export const ISmallStar = () => (
  <svg
    width="14"
    height="13"
    viewBox="0 0 14 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.55163 0.908494C6.73504 0.536871 7.26496 0.53687 7.44837 0.908493L8.94091 3.93272C9.01374 4.08029 9.15453 4.18258 9.31738 4.20624L12.6548 4.6912C13.0649 4.75079 13.2287 5.25478 12.9319 5.54404L10.5169 7.89808C10.3991 8.01294 10.3453 8.17844 10.3731 8.34064L10.9432 11.6646C11.0133 12.073 10.5846 12.3845 10.2178 12.1917L7.23267 10.6223C7.08701 10.5457 6.91299 10.5457 6.76733 10.6223L3.78224 12.1917C3.41543 12.3845 2.98671 12.073 3.05676 11.6646L3.62687 8.34064C3.65468 8.17844 3.60091 8.01294 3.48307 7.89808L1.06808 5.54404C0.771321 5.25478 0.935076 4.75079 1.34519 4.6912L4.68262 4.20624C4.84547 4.18258 4.98626 4.08029 5.05909 3.93272L6.55163 0.908494Z"
      fill="#FF9A2F"
    />
  </svg>
);
