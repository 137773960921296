import { MutableRefObject, useEffect } from 'react';
import { Amplitude, ANALYTICAL_EVENTS } from 'services/amplitude';

const payproOrigin = 'https://store.payproglobal.com';
const payproMsgEvent = 'thankyou-page-reached';

export function usePayproEvent({
  handlePaymentWaiting,
  handlePaymentSuccess,
  navigatingRef,
}: {
  handlePaymentSuccess: () => void;
  handlePaymentWaiting: () => void;
  navigatingRef: MutableRefObject<boolean>;
}) {
  useEffect(() => {
    const onMessage = ({ origin, data }: MessageEvent<any>) => {
      if (origin === payproOrigin && data?.event === payproMsgEvent) {
        // TODO: find out errors with paypro events
        Amplitude.logEvent(ANALYTICAL_EVENTS.PAYPRO_LOG_REDIRECT_EVENT, {
          origin,
          event: data?.event ?? 'unknown',
          status: data?.status ?? 'unknown',
        });
        if (navigatingRef.current) {
          return;
        }

        switch (data.status) {
          case 'purchase_successful':
            navigatingRef.current = true;
            return handlePaymentSuccess();
          case 'purchase_waiting':
            navigatingRef.current = true;
            return handlePaymentWaiting();
          case 'purchase_unsuccessful':
          default:
            break;
        }
      }
    };
    window.addEventListener('message', onMessage);

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);
}
