import { FC, useEffect, useRef, useState } from 'react';

import { Timeout } from 'types/module';

import { TextButton } from 'components/TextButton/TextButton';

const TIMER_INTERVAL = 1000;
const TIMER_MIN = 0;

interface ICountdownTextButton {
  maxSec: number;
  onClick: () => void;
}

export const CountdownTextButton: FC<ICountdownTextButton> = ({
  maxSec,
  children,
  onClick,
}) => {
  const [count, setCount] = useState(maxSec);
  const countTimerRef = useRef<Timeout>();

  useEffect(() => {
    if (count === maxSec) {
      countTimerRef.current && clearInterval(countTimerRef.current);
      countTimerRef.current = setInterval(() => {
        setCount(prev => prev - 1);
      }, TIMER_INTERVAL);
    } else if (count === TIMER_MIN) {
      clearInterval(countTimerRef.current!);
    }
  }, [count]);

  useEffect(() => {
    return () => {
      clearInterval(countTimerRef.current!);
    };
  }, []);

  const handleClick = () => {
    setCount(maxSec);
    onClick();
  };

  const disabled = count !== TIMER_MIN;

  return (
    <TextButton onClick={handleClick} disabled={disabled}>
      {children}
      {disabled && ` • ${count} sec`}
    </TextButton>
  );
};
