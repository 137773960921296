import { FC, HTMLAttributes } from 'react';
import styled, { css, useTheme } from 'styled-components';

import { isTouchDevice } from 'utils/common';

import { ISpinner } from 'components/Icons/ISpinner';
import { Pressable } from 'components/Pressable/Pressable';

interface IButton {
  loading?: boolean;
  size?: 'normal' | 'small';
  pressed?: boolean;
  disabled?: boolean;
  hoverColor?: string;
  palette?: 'default' | 'grey';
}

const CONFIGS = {
  normal: css`
    height: 56px;
    border-radius: 12px;
  `,
  small: css`
    height: 32px;
    border-radius: 8px;
  `,
};

const buttonHover = css<IButton>`
  &:hover {
    background-color: ${({
      disabled,
      theme,
      hoverColor = theme.colors.buttonsMarshIntense,
      palette,
    }) =>
      !disabled && palette === 'default'
        ? hoverColor
        : theme.colors.buttonsGreyIntense};
  }
`;

const StyledButton = styled.button<IButton>`
  ${({ size = 'normal' }) => CONFIGS[size]};

  transition: all 0.15s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;

  user-select: none;
  cursor: ${({ disabled }) => (disabled ? 'default' : 'pointer')};

  width: 100%;

  border: none;

  padding: 15px 32px 17px;
  background-color: ${({
    disabled,
    pressed,
    theme,
    hoverColor = theme.colors.buttonsMarshIntense,
    palette,
  }) =>
    `${
      disabled
        ? palette === 'default'
          ? theme.colors.backgroundSeparatorsSecondary
          : theme.colors.buttonsGreyIntense
        : pressed
        ? palette === 'default'
          ? hoverColor
          : theme.colors.buttonsGreyIntense
        : palette === 'default'
        ? theme.colors.buttonsMarshAccent
        : theme.colors.buttonsGreyAccent
    } ${pressed ? '!important' : ''}`};

  ${({ theme }) => theme.fonts.headlineBold};

  color: ${({ disabled, theme, palette }) =>
    disabled
      ? theme.colors.textAdditional
      : palette === 'default'
      ? theme.colors.white
      : theme.colors.black};

  ${!isTouchDevice() && buttonHover};
`;

const ButtonWrap = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 524px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-self: center;

  height: 100%;
  padding: 20px 0;
`;

type Props = IButton & HTMLAttributes<HTMLButtonElement>;

export const Button: FC<Props> = ({
  loading,
  children,
  onClick,
  palette = 'default',
  ...props
}) => {
  const theme = useTheme();

  const renderButton = (pressed?: boolean) => (
    <StyledButton
      loading={loading}
      pressed={pressed}
      onClick={loading ? undefined : onClick}
      palette={palette}
      {...props}>
      {loading ? <ISpinner color={theme.colors.white} /> : children}
    </StyledButton>
  );

  return isTouchDevice() ? (
    <Pressable>{renderButton}</Pressable>
  ) : (
    renderButton()
  );
};

export const SizedButton: FC<Props> = props => (
  <ButtonWrap>
    <Button {...props} />
  </ButtonWrap>
);
