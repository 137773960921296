import { css } from 'styled-components';

export const fonts = {
  //Unknown fonts
  steinbeck72: css`
    font-family: 'Steinbeck';
    font-weight: 400;
    font-size: 72px;
    line-height: 72px;
    letter-spacing: -0.6px;
  `,

  // Title fonts
  title1Regular: css`
    font-family: 'Steinbeck';
    font-weight: 400;
    font-size: 48px;
    line-height: 52px;
    letter-spacing: -1px;
  `,
  title1Italic: css`
    font-family: 'Steinbeck';
    font-weight: 400;
    font-size: 48px;
    font-style: italic;
    line-height: 52px;
    letter-spacing: -1px;
  `,

  title2Regular: css`
    font-family: 'Steinbeck';
    font-weight: 400;
    font-size: 40px;
    line-height: 44px;
    letter-spacing: -0.3px;
  `,
  title2Italic: css`
    font-family: 'Steinbeck';
    font-weight: 400;
    font-size: 40px;
    font-style: italic;
    line-height: 44px;
    letter-spacing: -0.3px;
  `,

  title3Regular: css`
    font-family: 'Steinbeck';
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
  `,
  title3Italic: css`
    font-family: 'Steinbeck';
    font-weight: 400;
    font-size: 32px;
    font-style: italic;
    line-height: 36px;
  `,

  title4Black: css`
    font-family: 'Inter';
    font-weight: 900;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.69px;
  `,
  title4Extra: css`
    font-family: 'Inter';
    font-weight: 800;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.69px;
  `,
  title4Bold: css`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.69px;
  `,
  title4Semibold: css`
    font-family: 'Inter';
    font-weight: 600;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.69px;
  `,
  title4Regular: css`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
    letter-spacing: -0.69px;
  `,

  title5Black: css`
    font-family: 'Inter';
    font-weight: 900;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.47px;
  `,
  title5Extra: css`
    font-family: 'Inter';
    font-weight: 800;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.47px;
  `,
  title5Bold: css`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.47px;
  `,
  title5Semibold: css`
    font-family: 'Inter';
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.47px;
  `,
  title5Regular: css`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.47px;
  `,

  title6Black: css`
    font-family: 'Inter';
    font-weight: 900;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.33px;
  `,
  title6BExtra: css`
    font-family: 'Inter';
    font-weight: 800;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.33px;
  `,
  title6Bold: css`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.33px;
  `,
  title6Semibold: css`
    font-family: 'Inter';
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.33px;
  `,
  title6Regular: css`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    letter-spacing: -0.33px;
  `,

  title7Extra: css`
    font-family: 'Inter';
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.26px;
  `,
  title7Bold: css`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.26px;
  `,
  title7Semibold: css`
    font-family: 'Inter';
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.26px;
  `,
  title7Regular: css`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.26px;
  `,
  title8Bold: css`
    font-family: 'Inter';
    font-size: 28px;
    font-weight: 700;
    line-height: 32px;
    letter-spacing: -0.02em;
    text-align: center;
  `,
  title8Extra: css`
    font-family: 'Inter';
    font-size: 28px;
    font-weight: 900;
    line-height: 32px;
    letter-spacing: -0.02em;
    text-align: center;
  `,
  // Headline fonts
  headlineExtra: css`
    font-family: 'Inter';
    font-weight: 800;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.13px;
  `,
  headlineBold: css`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.13px;
  `,
  headlineSemibold: css`
    font-family: 'Inter';
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.13px;
  `,
  headlineRegular: css`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.13px;
  `,
  headlineMedium: css`
    font-family: 'Inter';
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.13px;
  `,
  headlineSubtitle: css`
    font-family: 'Inter';
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  `,
  headlineSubtitleBold: css`
    font-family: 'Inter';
    font-size: 17px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: center;
  `,

  // Body fonts
  bodyBold: css`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.13px;
  `,
  bodySemibold: css`
    font-family: 'Inter';
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.13px;
  `,
  bodyRegular: css`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
    letter-spacing: -0.13px;
  `,

  // Subhead fonts
  subheadBold: css`
    font-family: 'Inter';
    font-weight: 700;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.04px;
  `,
  subheadExtra: css`
    font-family: 'Inter';
    font-weight: 800;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.04px;
  `,
  subheadSemibold: css`
    font-family: 'Inter';
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.04px;
  `,
  subheadRegular: css`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.04px;
  `,
  subheadMedium: css`
    font-family: 'Inter';
    font-weight: 500;
    font-size: 13px;
    line-height: 18px;
    letter-spacing: -0.04px;
  `,

  // Caption fonts
  captionSemibold: css`
    font-family: 'Inter';
    font-weight: 600;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.05px;
  `,
  captionRegular: css`
    font-family: 'Inter';
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 0.05px;
  `,

  subtitleDescription: css`
    font-family: Inter;
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.12999999523162842px;
    text-align: center;
  `,

  // Note caps fonts
  noteCapsSemibold: css`
    font-family: 'Inter';
    font-weight: 600;
    font-size: 10px;
    line-height: 12px;
    letter-spacing: 1px;
  `,
  // Display fonts
  display1Regular: css`
    font-family: 'Steinbeck';
    font-weight: 400;
    font-size: 48px;
    line-height: 52px;
    letter-spacing: -1px;
  `,
  display2Regular: css`
    font-family: 'Steinbeck';
    font-weight: 400;
    font-size: 40px;
    line-height: 44px;
    letter-spacing: -0.5px;
  `,
  display3Regular: css`
    font-family: 'Steinbeck';
    font-weight: 400;
    font-size: 32px;
    line-height: 36px;
  `,
  display4Regular: css`
    font-family: 'Steinbeck';
    font-weight: 400;
    font-size: 28px;
    line-height: 32px;
  `,
  display1Italic: css`
    font-family: 'Steinbeck';
    font-weight: 400;
    font-style: italic;
    font-size: 48px;
    line-height: 52px;
    letter-spacing: -1px;
  `,
  display2Italic: css`
    font-family: 'Steinbeck';
    font-weight: 400;
    font-style: italic;
    font-size: 40px;
    line-height: 44px;
    letter-spacing: -0.5px;
  `,
  display3Italic: css`
    font-family: 'Steinbeck';
    font-weight: 400;
    font-style: italic;
    font-size: 32px;
    line-height: 36px;
  `,
  display4Italic: css`
    font-family: 'Steinbeck';
    font-weight: 400;
    font-style: italic;
    font-size: 28px;
    line-height: 32px;
  `,
  display4Bold: css`
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 114.286% */
    letter-spacing: -0.56px;
  `,

  //Paragraph
  paragraphRegular: css`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 22px;
  `,
  paragraphSmall: css`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-size: 13px;
    line-height: 22px;
  `,
  paragraphMedium: css`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 22px;
  `,
  paragraphSemibold: css`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 22px;
  `,
  paragraphBold: css`
    font-family: 'Inter';
    font-style: normal;
    font-weight: 800;
    font-size: 15px;
    line-height: 22px;
  `,

  //Annotation
  annotationRegular: css`
    font-family: 'Inter';
    font-style: normal;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0;
  `,
} as const;
