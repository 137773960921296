import { AppConfig } from 'config';

import { Amplitude } from 'services/amplitude';
import { FirebaseService } from 'services/remoteConfig';

import { toBase64 } from 'utils/base64';
import { serializeQueryParams } from 'utils/formaters';

export const payproCheckoutUrl = 'https://store.payproglobal.com/checkout';

export interface BuildPayproProps {
  payproPriceId: string;
  email: string;
  accountId: string;
  currency: string;
  coupon?: string;
  startUrl?: string;
  urlParams?: string;
  deviceParams?: string;
  startPage?: string;
}

export const buildPayproParams = ({
  payproPriceId,
  email,
  accountId,
  currency,
  coupon,
  startUrl,
  urlParams,
  deviceParams,
  startPage,
}: BuildPayproProps) => {
  const {
    templateId,
    accountIdField,
    deviceIdField,
    startUrlField,
    urlParamsField,
    deviceParamsField,
  } = FirebaseService.payproConfig;

  const params = {
    'products[1][id]': payproPriceId!,
    'page-template': templateId,
    'billing-email': email,
    [`custom-fields[${accountIdField}][]`]: accountId,
    [`custom-fields[${deviceIdField}][]`]: Amplitude.deviceId,
    'enable-auto-renewal': 'TRUE',
    'payment-method': '1', // credit card
    'use-test-mode': AppConfig.IS_DEV,
    currency,
    exfo: 742, // param to open checkout as an iframe
  };
  if (AppConfig.IS_DEV) {
    params['secret-key'] = AppConfig.PAYPRO_SECRET_KEY;
  }
  if (coupon) {
    params['coupon-code-to-add'] = coupon;
  }

  if (startUrlField && startUrl) {
    params[`custom-fields[${startUrlField}][]`] = toBase64(startUrl);
  }
  if (urlParamsField && urlParams) {
    params[`custom-fields[${urlParamsField}][]`] = toBase64(urlParams);
  }
  if (deviceParamsField && deviceParams) {
    params[`custom-fields[${deviceParamsField}][]`] = toBase64(deviceParams);
  }
  if (startPage) {
    params['custom-fields[startPage][]'] = startPage;
  }
  return `${payproCheckoutUrl}?${serializeQueryParams(params)}`;
};
