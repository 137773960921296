import { ISpinner } from 'components/Icons/ISpinner';

import { Center, LoadingContainer } from './LoadingState.styles';

export const LoadingState = () => {
  return (
    <LoadingContainer>
      <Center>
        <ISpinner size="large" />
      </Center>
    </LoadingContainer>
  );
};
