import { observer } from 'mobx-react-lite';
import { useEffect, useRef } from 'react';

import { FirebaseService } from 'services/remoteConfig';

import { Timeout } from 'types/module';

import { useMst } from 'stores/RootStore';

import { privacyLink, termsLink } from 'constants/common';

import { SizedButton } from 'components/Button/Button';
import { I24Lock } from 'components/Icons/I24Lock';
import { LinkText } from 'components/primitives';

import { AuthVariant } from './types';
import { isOnboardingAuth } from './utils';

import { CountdownTextButton } from './CountdownTextButton/CountdownTextButton';
import { EmailInputComponent } from './EmailInputComponent';
import { MagicCode } from './MagicCode';

import {
  BottomText,
  BottomTextWrapper,
  ButtonWrap,
  Container,
  CountdownWrap,
  LockRow,
  LockText,
  SecondaryButton,
} from './AuthModule.styles';
import { PixelServices } from 'services/pixelServices';

interface IAuthModule {
  title: string;
  subtitle?: string;
  showTerms?: boolean;
  autofocus?: boolean;
  onClickLoginButton?: () => void;
  onClickTextButton?: () => void;
  onSuccessCode: () => void;
  loginButtonLabel?: string;
  textButtonLabel?: string;
  variant?: AuthVariant;
}

export const AuthModule = observer(
  ({
    autofocus,
    onClickLoginButton,
    onClickTextButton,
    onSuccessCode,
    textButtonLabel,
    loginButtonLabel = 'Continue',
    showTerms,
    title,
    subtitle,
    variant = 'login',
  }: IAuthModule) => {
    const { authStore } = useMst();
    const inputRef = useRef<HTMLInputElement>(null);
    const timerRef = useRef<Timeout>();

    useEffect(() => {
      if (!authStore.tempEmail) {
        PixelServices.twitterTrack('tw-o98ee-ok1w6', {});
      }
    }, []);

    useEffect(() => {
      if (autofocus) {
        timerRef.current && clearTimeout(timerRef.current);
        timerRef.current = setTimeout(() => {
          inputRef?.current?.focus();
        }, 1000);
      }
      return clear;
    }, [autofocus]);

    const clear = () => {
      authStore.setIsVerifyingCode(false);
      authStore.clearError();
      inputRef?.current?.blur();
    };

    const renderButton = () => {
      return (
        <ButtonWrap>
          {authStore.isVerifyingCode ? (
            <CountdownWrap>
              <CountdownTextButton
                maxSec={60}
                onClick={() => authStore.applyEmail()}>
                Resend code
              </CountdownTextButton>
            </CountdownWrap>
          ) : (
            <>
              <SizedButton
                loading={authStore.isLoading}
                disabled={!authStore.canContinue}
                onClick={onClickLoginButton}>
                {loginButtonLabel}
              </SizedButton>
              {onClickTextButton && (
                <SecondaryButton onClick={onClickTextButton}>
                  {textButtonLabel}
                </SecondaryButton>
              )}
            </>
          )}
        </ButtonWrap>
      );
    };

    const isOnboardingWithSkip =
      isOnboardingAuth(variant) && FirebaseService.canSkipAuth;

    const lockText = (
      <>
        We respect your privacy and we are committed to protecting your personal
        data. By continuing you indicate that you've read and agree to our{' '}
        <LinkText target="_blank" href={termsLink}>
          Terms & Conditions
        </LinkText>
        {' and '}
        <LinkText target="_blank" href={privacyLink}>
          Privacy Policy
        </LinkText>
      </>
    );

    return (
      <Container variant={variant}>
        {authStore.isVerifyingCode ? (
          <MagicCode onSuccess={onSuccessCode} />
        ) : (
          <EmailInputComponent
            title={title}
            subtitle={subtitle}
            inputRef={inputRef}
            value={authStore.tempEmail}
            onChange={authStore.setTempEmail}
            placeholder={'e.g. michel@gmail.com'}
            errorMessage={authStore.errorMessage}
            variant={variant}
          />
        )}

        {!authStore.isVerifyingCode && (
          <LockRow>
            {!isOnboardingWithSkip && <I24Lock width={52} height={52} />}
            <LockText center={isOnboardingWithSkip}>{lockText}</LockText>
          </LockRow>
        )}

        {renderButton()}
        {showTerms && (
          <BottomTextWrapper>
            <BottomText>
              We respect your privacy and we are committed to protecting your
              personal data. By continuing you indicate that you've read and
              agree to our{' '}
              <LinkText target="_blank" href={termsLink}>
                Terms & Conditions
              </LinkText>
              {' and '}
              <LinkText target="_blank" href={privacyLink}>
                Privacy Policy
              </LinkText>
            </BottomText>
          </BottomTextWrapper>
        )}
      </Container>
    );
  },
);
