import AmplitudeAPI from 'amplitude-js';

import { AppConfig } from 'config';

import { GA } from './ga';

export enum ANALYTICAL_EVENTS {
  FIRST_LAUNCH = '[Web] First Launch',
  START = '[Web] Start',
  WELCOME_START = '[Web] Welcome screen',

  ONBOARDING_START = '[Web] Onboarding | Start',
  ONBOARDING_PAGE = '[Web] Onboarding | Page',
  ONBOARDING_EMAIL = '[Web] Onboarding | Email',
  ONBOARDING_EMAIL_OPEN = '[Web] Onboarding | Open Email',
  ONBOARDING_EMAIL_SKIP = '[Web] Onboarding | Skip Email',

  AFTER_PAYWALL = '[Web] After Paywall',
  AFTER_PAYWALL_TRY_SUBSCRIBE = '[Web] After Paywall | Try ad-on purchase',
  AFTER_PAYWALL_TYPE_SUBSCRIBE = '[Web] After Paywall | ad-on purchase type',
  AFTER_PAYWALL_WAITING_PAYMENT = '[Web] After Paywall | Waiting for Payment',
  AFTER_PAYWALL_PURCHASE_SUBSCRIBE = '[Web] [Subscription Client] After Purchase',
  AFTER_PAYWALL_ERROR = '[Web] [Subscription Client] After Purchase | Error',

  UPGRADE_PAYWALL = '[Web] After Paywall',
  UPGRADE_PAYWALL_TRY_SUBSCRIBE = '[Web] Upgrade Paywall | Try ad-on purchase',
  UPGRADE_PAYWALL_TYPE_SUBSCRIBE = '[Web] Upgrade Paywall | ad-on purchase type',
  UPGRADE_PAYWALL_WAITING_PAYMENT = '[Web] Upgrade Paywall | Waiting for Payment',
  UPGRADE_PAYWALL_PURCHASE_SUBSCRIBE = '[Web] [Subscription Client] After Purchase',
  UPGRADE_PAYWALL_ERROR = '[Web] [Subscription Client] Upgrade Purchase | Error',

  PREMIUM_UPGRADE_PAYWALL = '[Web] Premium After Paywall',
  PREMIUM_UPGRADE_PAYWALL_PURCHASE_SUBSCRIBE = '[Web] [Subscription Client] Premium After Purchase',
  PREMIUM_UPGRADE_PAYWALL_TYPE_SUBSCRIBE = '[Web] Premium Upgrade Paywall | ad-on purchase type',
  PREMIUM_UPGRADE_PAYWALL_TRY_SUBSCRIBE = '[Web] Premium Upgrade Paywall | Try ad-on purchase',
  PREMIUM_UPGRADE_PAYWALL_ERROR = '[Web] [Subscription Client] Premium Upgrade Purchase | Error',

  PAYWALL = '[Web] Paywall',
  PAYWALL_TRY_SUBSCRIBE = '[Web] Paywall | Try subscribe',
  PAYWALL_TYPE_SUBSCRIBE = '[Web] Paywall | Subscribe type',
  PAYWALL_WAITING_PAYMENT = '[Web] Paywall | Waiting for Payment',
  PAYWALL_PURCHASE_SUBSCRIBE = '[Web] [Subscription Client] Purchase',
  PAYWALL_TERMS = '[Web] Paywall | Terms',
  PAYWALL_PRIVACY_POLICY = '[Web] Paywall | Privacy Policy',
  PAYWALL_EMAIL = '[Web] Paywall | Email ',
  PAYWALL_POPUP_OPEN = '[Web] Paywall | Open Popup',

  CONFIRM_EMAIL = '[Web] Confirm Email',
  CONFIRM_EMAIL_CLICK = '[Web] Confirm Email Send',
  SUCCESS_GUIDE = '[Web] Success guide',

  DISCOUNT_PAGE = '[Web] Discount Page',

  PAYPRO_LOG_REDIRECT_EVENT = '[Web] Paypro log redirect',
}

class AmplitudeService {
  private ampInstance: AmplitudeAPI.AmplitudeClient | null;

  constructor(apiKey: string, enable: boolean) {
    this.ampInstance = enable ? AmplitudeAPI.getInstance() : null;
    this.ampInstance?.init(apiKey, undefined, { includeUtm: true });

    if (this.isNewSession()) {
      this.logEvent(ANALYTICAL_EVENTS.FIRST_LAUNCH);
    }
    this.logEvent(ANALYTICAL_EVENTS.START);
  }

  public logEvent = (eventName: ANALYTICAL_EVENTS, data?: any) => {
    this.ampInstance?.logEvent(eventName, data);
    GA.event(eventName, data);
  };

  isNewSession() {
    return this.ampInstance?.isNewSession();
  }

  public setUserProperties = (data: any) => {
    this.ampInstance?.setUserProperties(data);
  };

  public setUserId = (userId: string | null) => {
    this.ampInstance?.setUserId(userId);
  };

  get deviceId() {
    return this.ampInstance?.options.deviceId!;
  }
}

export const Amplitude = new AmplitudeService(
  AppConfig.AMPLITUDE_API_KEY,
  AppConfig.APPLICATION_ENV === 'prod',
);
