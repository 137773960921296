/* eslint-disable react-native/no-inline-styles */
type Props = {
  amount: number;
};

const DISCOUNT_BACKGROUND_LINK =
  'https://app.sensualcourse.com/DiscountBannerBackground.png';

export const DiscountBanner = ({ amount }: Props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width={'100%'}
      viewBox="0 0 359 375"
      fill="none"
      style={{ userSelect: 'none', maxWidth: 650 }}>
      <path fill="url(#a)" d="M0 0h375v359H0z" />
      <text
        y="200"
        x="40"
        fontFamily="Inter"
        fill="white"
        fontSize={85}
        fontWeight={600}
        accentHeight={102}
        style={{ transform: 'rotate(-13.39deg)' }}>
        {amount}
      </text>
      <text
        y="230"
        x="50"
        fontFamily="Inter"
        fill="white"
        fontSize={32}
        fontWeight={700}
        style={{ transform: 'rotate(-13.39deg)' }}>
        OFF
      </text>
      <text
        y="160"
        x="145"
        fontFamily="Inter"
        fill="white"
        fontSize={28}
        fontWeight={900}
        style={{ transform: 'rotate(-13.39deg)' }}>
        %
      </text>
      <defs>
        <pattern
          id="a"
          width={1}
          height={1}
          patternContentUnits="objectBoundingBox">
          <use xlinkHref="#b" transform="matrix(.00047 0 0 .00049 .043 0)" />
        </pattern>
        <image
          href={DISCOUNT_BACKGROUND_LINK}
          id="b"
          width={2048}
          height={2048}
        />
      </defs>
    </svg>
  );
};
