import React, { HTMLProps } from 'react';

import { LogoNavButton } from 'components/LogoNavButton/LogoNavButton';

import { LeftContent } from './LeftContent';

import {
  HeaderContainer,
  HeaderTitle,
  HeaderWrapper,
  LeftWrapper,
  Line,
  RightWrapper,
  TitleWrapper,
} from './PageHeader.styles';

interface IPageHeader extends Omit<HTMLProps<HTMLDivElement>, 'as' | 'ref'> {
  bottomContent?: () => React.ReactNode;
  rightContent?: () => React.ReactNode;
  leftContent?: () => React.ReactNode;
  centerContent?: () => React.ReactNode;
  headerTitleStyle?: React.CSSProperties;
  hideBottomLine?: boolean;
  showLogoButton?: boolean;
  title?: string;
  isSticky?: boolean;
}

export const PageHeader: React.FC<IPageHeader> = ({
  bottomContent,
  rightContent,
  leftContent,
  centerContent,
  title,
  headerTitleStyle,
  hideBottomLine,
  showLogoButton,
  ...props
}) => {
  return (
    <HeaderContainer {...props}>
      <HeaderWrapper>
        <LeftWrapper>
          {showLogoButton ? (
            <LogoNavButton />
          ) : leftContent ? (
            leftContent()
          ) : (
            <LeftContent />
          )}
        </LeftWrapper>

        {centerContent ? (
          centerContent()
        ) : (
          <TitleWrapper>
            {!!title && (
              <HeaderTitle style={headerTitleStyle}>{title}</HeaderTitle>
            )}
          </TitleWrapper>
        )}

        {rightContent && <RightWrapper>{rightContent()}</RightWrapper>}
      </HeaderWrapper>

      {bottomContent ? bottomContent() : !hideBottomLine && <Line />}
    </HeaderContainer>
  );
};
