import React from 'react';
import { useTheme } from 'styled-components';

export const ICross = (props: React.SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      color={theme.colors.iconsPrimary}
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.72208 4.72221C5.15166 4.29263 5.84814 4.29263 6.27772 4.72221L11.9999 10.4444L17.7221 4.72221C18.1517 4.29263 18.8481 4.29263 19.2777 4.72221C19.7073 5.15178 19.7073 5.84827 19.2777 6.27784L13.5555 12L19.2777 17.7222C19.7073 18.1518 19.7073 18.8483 19.2777 19.2778C18.8481 19.7074 18.1517 19.7074 17.7221 19.2778L11.9999 13.5557L6.27772 19.2778C5.84814 19.7074 5.15166 19.7074 4.72208 19.2778C4.29251 18.8483 4.29251 18.1518 4.72208 17.7222L10.4443 12L4.72208 6.27784C4.29251 5.84827 4.29251 5.15178 4.72208 4.72221Z"
        fill="currentColor"
      />
    </svg>
  );
};
