import { createContext, FC, useEffect, useState } from 'react';

import { FirebaseService } from 'services/remoteConfig';

interface ISubrouteContext {
  subrouteName: null | string;
  setSubrouteName: (value: string) => void;
}

export const SubrouteContext = createContext<ISubrouteContext>({
  subrouteName: null,
  setSubrouteName: () => {},
});

export const SubrouteProvider: FC = ({ children }) => {
  const [subrouteName, setSubrouteName] =
    useState<ISubrouteContext['subrouteName']>(null);

  useEffect(() => {
    if (subrouteName !== null) {
      FirebaseService.applyPage(subrouteName);
    }
  }, [subrouteName]);

  return (
    <SubrouteContext.Provider
      value={{ subrouteName: subrouteName ?? '', setSubrouteName }}>
      {children}
    </SubrouteContext.Provider>
  );
};
