import {
  PaymentRequestButtonElement,
  useStripe,
} from '@stripe/react-stripe-js';
import { PaymentRequest } from '@stripe/stripe-js';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { PaymentStates } from 'stores/Payment';
import { useMst } from 'stores/RootStore';

import { Amplitude, ANALYTICAL_EVENTS } from 'services/amplitude';
import { SensesSDK } from 'services/api/senses';

import { useStripePaywall } from 'hooks/useStripePaywall';

import { DISABLED_BUTTONS_DATA, PaymentTypes } from 'constants/common';

import { getStripeButtonText } from 'pages/PaywallPage/utils';

import { Button } from 'components/Button/Button';

import { FormInputs } from './FormInputs/FormInputs';

import {
  DisabledButton,
  DisabledButtonWrap,
  FormContainer,
  FormContainerBottomBlock,
  FormContainerTopBlock,
  FormContainerTopText,
  SubmitButtonWrap,
} from './StripeFormContainer.style';
import { GA } from 'services/ga';
import { analitics } from 'services/analytics';

const PaymentRequestButtonElementStyled = styled(PaymentRequestButtonElement)`
  width: 100%;
  padding: 16px;
`;

interface IStripeFormContainer {
  cardLayout: boolean;
  buttonText?: string;
  handlePaymentSuccess: () => void;
  handlePaymentError: () => void;
}

export const StripeFormContainer = observer(
  ({
    cardLayout,
    buttonText,
    handlePaymentSuccess,
    handlePaymentError,
  }: IStripeFormContainer) => {
    const paywallElements = useStripePaywall();
    const {
      paymentStore,
      authStore: { email },
    } = useMst();

    const stripe = useStripe();
    const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | null>(
      null,
    );

    const product = paymentStore.stripeProductData;

    useEffect(() => {
      if (stripe) {
        const pr = stripe.paymentRequest({
          country: 'US',
          currency: product?.currency ?? 'usd',
          total: {
            label: 'Senses Subscription',
            amount: product?.trial.amount ?? 0,
          },
          requestPayerName: true,
          requestPayerEmail: true,
        });

        // Check the availability of the Payment Request API.
        pr.canMakePayment().then(result => {
          if (result) {
            setPaymentRequest(pr);
          }
        });

        pr.on('paymentmethod', async ev => {
          /*
          const stripeIntentData = await SensesSDK.stripeGetSetupIntent({
            amount: product?.amount ?? 0,
            currency: product?.currency ?? 'USD',
          });
          const stripePaymentIntent = await SensesSDK.stripeGetPaymentIntet({
            amount: product?.amount ?? 0,
            currency: product?.currency ?? 'USD',
          });
          */
          await SensesSDK.stripeSubscriptionCreate({
            paymentMethodId: ev.paymentMethod.id,
            priceId: product?.price_id || '',
            email: email,
            gaClientId: GA.clientId,
            urlParams: JSON.stringify(analitics.startUTMParams),
          });
          handlePaymentSuccess();
          ev.complete('success');
          /*
          console.log('SETUP_INTENT ', JSON.stringify(stripeIntentData));
          console.log(
            'SETUP_INTENT_PAYMENT ',
            JSON.stringify(stripePaymentIntent),
          );
          if (!stripeIntentData.data || !stripeIntentData.data.client_secret) {
            console.log('error confirming payment');
            paymentStore.resetPaymentState();
            ev.complete('fail');
            return;
          }

          const stripeClientSecret = stripeIntentData.data.client_secret;

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          const intent = await stripe.retrieveSetupIntent(
            stripeClientSecret || '',
          );
          console.log(intent == null);
          const _paymentIntent = stripePaymentIntent.data.client_secret;

          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          await stripe.confirmCardPayment(
            _paymentIntent,
            { payment_method: ev.paymentMethod.id },
            { handleActions: false },
          );
          paymentStore.setPaymentApproved();
          */
          /*
          if (confirmError) {
            console.log('error confirming payment');
            paymentStore.resetPaymentState();
            ev.complete('fail');
          } else {
            ev.complete('success');

            if (paymentIntent?.status === 'requires_action') {
              const { error } = await stripe.confirmCardPayment(_paymentIntent);
              if (error) {
                console.log('error confirming card payment');
                paymentStore.resetPaymentState();
                handlePaymentError();
              } else {
                paymentStore.setPaymentApproved();
                handlePaymentSuccess();
              }
            } else {
              paymentStore.setPaymentApproved();
              handlePaymentSuccess();
            }
          }
          */
        });
      }
    }, [stripe]);

    const buttonContent = buttonText || getStripeButtonText(product);

    const isInputDisabled =
      paymentStore.paymentState === PaymentStates.stripeLoading;

    const onClickPay = () => {
      if (paywallElements.inputError) {
        // TODO, если сразу нажать continue, то не покажутся ошибки в инпутах, а сразу покажется окно try again
        return;
      }
      Amplitude.logEvent(ANALYTICAL_EVENTS.PAYWALL_TRY_SUBSCRIBE, {
        source: PaymentTypes.STRIPE,
      });
      const addressZip = paywallElements.addressZipProps.value;
      paymentStore.createCardPaySubscription({
        cardPay: { cardData: 'CardNumberElement', addressZip },
        userInfo: { email },
        onSuccess: handlePaymentSuccess,
        onError: handlePaymentError,
      });
    };

    return (
      <>
        <FormContainer cardLayout={cardLayout}>
          <FormContainerTopBlock cardLayout={cardLayout}>
            <FormContainerTopText>Payment Info</FormContainerTopText>
          </FormContainerTopBlock>
          <FormContainerBottomBlock cardLayout={cardLayout}>
            {cardLayout && (
              <DisabledButtonWrap cardLayout={cardLayout}>
                {DISABLED_BUTTONS_DATA.map(({ src }) => (
                  <DisabledButton key={src} size="small" disabled>
                    <img src={src} key={src} />
                  </DisabledButton>
                ))}
              </DisabledButtonWrap>
            )}
            <FormInputs {...paywallElements} disabled={isInputDisabled} />
          </FormContainerBottomBlock>
          {!cardLayout && (
            <DisabledButtonWrap cardLayout={cardLayout}>
              {DISABLED_BUTTONS_DATA.map(({ src }) => (
                <DisabledButton key={src} size="small" disabled>
                  <img src={src} key={src} />
                </DisabledButton>
              ))}
            </DisabledButtonWrap>
          )}
        </FormContainer>
        {!!paymentRequest && (
          <PaymentRequestButtonElementStyled options={{ paymentRequest }} />
        )}
        <SubmitButtonWrap>
          <Button loading={paymentStore.isLoading} onClick={onClickPay}>
            {buttonContent}
          </Button>
        </SubmitButtonWrap>
      </>
    );
  },
);
