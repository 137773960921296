import { AnimatePresence } from 'framer-motion';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { PaymentStates } from 'stores/Payment';
import { useMst } from 'stores/RootStore';

import { Amplitude, ANALYTICAL_EVENTS } from 'services/amplitude';

import { useRelativeNavigation } from 'hooks/useRelativeNavigation';

import { APP_ROUTES } from 'navigations/Routs';

import { PaymentSection } from 'pages/PaywallPage/components/PaymentSection/PaymentSection';

import { Button } from 'components/Button/Button';
import { Popup } from 'components/Popup';

import { Content } from '../Content/Content';

import {
  ButtonWrap,
  Center,
  LoadingContainer,
  PaymentWrap,
} from './AuthorizedView.styles';
import { PixelServices } from 'services/pixelServices';

export const AuthorizedView = observer(() => {
  const { paymentStore } = useMst();
  const { push } = useRelativeNavigation();

  useEffect(() => {
    Amplitude.logEvent(ANALYTICAL_EVENTS.PAYWALL_POPUP_OPEN);
    PixelServices.twitterTrack('tw-o98ee-ok1w3', {});
  }, []);

  const { paymentState } = paymentStore;

  const showPayment =
    paymentState === PaymentStates.initial ||
    paymentState === PaymentStates.stripeLoading;
  const success = paymentState === PaymentStates.success;
  const loading = paymentState === PaymentStates.loading;

  const onSuccess = () => {
    push(APP_ROUTES.SUCCESS);
  };

  const onContinue = () => {
    if (success) {
      Popup.close();
      onSuccess();
    } else {
      paymentStore.resetPaymentState();
    }
  };

  return (
    <>
      {showPayment && (
        <PaymentWrap showPayment={showPayment}>
          <PaymentSection
            cardLayout={false}
            showTitle={false}
            stripeButtonText="Continue"
          />
        </PaymentWrap>
      )}
      {!showPayment && (
        <AnimatePresence>
          <LoadingContainer>
            <Center>
              <Content paymentState={paymentState} />
            </Center>
            {!loading && (
              <ButtonWrap>
                <Button onClick={onContinue}>Continue</Button>
              </ButtonWrap>
            )}
          </LoadingContainer>
        </AnimatePresence>
      )}
    </>
  );
});
