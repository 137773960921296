import styled from 'styled-components';

export const TextButton = styled.button`
  user-select: none;
  cursor: pointer;

  width: auto;
  height: auto;

  border: none;

  ${({ theme }) => theme.fonts.headlineBold};
  color: ${({ theme, disabled }) =>
    disabled
      ? theme.colors.buttonsTextDisabled
      : theme.colors.buttonsMarshAccent};

  &:hover {
    color: ${({ theme, disabled }) =>
      disabled
        ? theme.colors.buttonsTextDisabled
        : theme.colors.buttonsMarshIntense};
  }
`;
