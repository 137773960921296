import { useLayoutEffect, useRef } from 'react';

export const useWindowHeight = () => {
  const windowHeightRef = useRef(0);
  const screenHeightRef = useRef(0);
  useLayoutEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty('--window-height', `${window.innerHeight}px`);
      doc.style.setProperty('--screen-height', `${window.screen.height}px`);
      windowHeightRef.current = window.innerHeight;
      screenHeightRef.current = window.screen.height;
    };
    appHeight();
    window.addEventListener('resize', appHeight);
    appHeight();
    return () => {
      window.removeEventListener('resize', appHeight);
    };
  }, []);

  return { windowHeightRef, screenHeightRef };
};
