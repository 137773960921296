import { observer } from 'mobx-react-lite';
import { useCallback } from 'react';

import { AppConfig } from 'config';

import { useMst } from 'stores/RootStore';

import { Amplitude, ANALYTICAL_EVENTS } from 'services/amplitude';

import { PaymentTypes } from 'constants/common';

import { getPaypalFeatures } from '../../../utils';
import { FeatureItem } from './FeatureItem';
import { PayPalButton } from './PayPalButton';

import { Container, FeaturesWrapper } from './PaypalContainer.style';

const buttonStyle = {
  layout: 'vertical',
  shape: 'pill',
  label: 'pay',
  height: 48,
  tagline: false,
};

const paypalOptions = {
  vault: true,
  intent: 'subscription',
  clientId: AppConfig.PAYPAL_CLIENT_ID,
};

interface IOnPaypalApproveData {
  billingToken?: string | null;
  facilitatorAccessToken: string;
  orderID: string;
  payerID?: string | null;
  paymentID?: string | null;
  subscriptionID?: string | null;
  authCode?: string | null;
}

interface IPaypalContainer {
  handlePaymentSuccess: () => void;
  handlePaymentError: () => void;
  handleCancelPayment: () => void;
}

export const PaypalContainer = observer(
  ({
    handlePaymentSuccess,
    handlePaymentError,
    handleCancelPayment,
  }: IPaypalContainer) => {
    const { paymentStore } = useMst();

    const product = paymentStore.paypalProductData;
    const paypalFeatures = getPaypalFeatures(product);

    const handlePayPalApprove = useCallback((data: IOnPaypalApproveData) => {
      paymentStore
        .confirmPaypalSubscription(data.subscriptionID)
        .then(confirmed => {
          if (confirmed) {
            paymentStore.setPaymentApproved();
            handlePaymentSuccess();
          }
        });
    }, []);

    const handlePayPalError = useCallback((err: unknown) => {
      handlePaymentError();
      console.log('____PAYWALL ERROR____', err);
    }, []);

    const handleCreateSubscription = useCallback(() => {
      Amplitude.logEvent(ANALYTICAL_EVENTS.PAYWALL_TRY_SUBSCRIBE, {
        source: PaymentTypes.PAYPAL,
      });
      return paymentStore.createPayPalSubscription(Amplitude.deviceId);
    }, []);

    return (
      <Container>
        <FeaturesWrapper>
          {paypalFeatures.map(feature => (
            <FeatureItem key={feature} feature={feature} />
          ))}
        </FeaturesWrapper>
        <PayPalButton
          options={paypalOptions}
          style={buttonStyle}
          createSubscription={handleCreateSubscription}
          onApprove={handlePayPalApprove}
          onError={handlePayPalError}
          onCancel={handleCancelPayment}
        />
      </Container>
    );
  },
);
