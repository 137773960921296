import { SVGProps } from 'react';
import { useTheme } from 'styled-components';

export const Quote = (props: SVGProps<SVGSVGElement>) => {
  const { colors } = useTheme();

  return (
    <svg
      width={46}
      height={29}
      viewBox="0 0 46 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={colors.accentMarsh}
      {...props}>
      <path
        d="M8.767 19.108C3.455 19.108 0 14.995 0 9.632 0 4.061 4.28 0 9.901 0c5.88 0 10.263 4.738 10.263 11.402C20.164 23.482 10.365 29 5.93 29c-1.237 0-2.165-.885-2.165-2.083 0-1.04.515-1.77 2.114-2.186 2.888-.781 6.962-3.437 8.664-7.706h-.413c-1.29 1.614-3.3 2.083-5.363 2.083zM34.552 19.108c-5.312 0-8.716-4.113-8.716-9.476 0-5.571 4.229-9.632 9.85-9.632C41.616 0 46 4.738 46 11.402 46 23.482 36.202 29 31.715 29c-1.237 0-2.114-.885-2.114-2.083 0-1.04.464-1.77 2.114-2.186 2.837-.781 6.91-3.437 8.612-7.706h-.36c-1.29 1.614-3.301 2.083-5.415 2.083z"
        fill="currentColor"
      />
    </svg>
  );
};
