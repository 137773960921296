import styled from 'styled-components';

type Props = {
  time: string;
};

const ExpirationWrapper = styled.section`
  margin-bottom: 16px;
  padding: 8px 16px 8px 16px;
  border-radius: 1000px;

  background: ${({ theme }) => theme.colors.blackNinth};
  text-transform: uppercase;
  width: fit-content;

  ${({ theme }) => theme.fonts.subheadBold};
`;

export const DiscountExpiration = ({ time }: Props) => {
  return <ExpirationWrapper>{`discount expires in ${time}`}</ExpirationWrapper>;
};
