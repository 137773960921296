import { UAParser } from 'ua-parser-js';

import { entriesToObject } from 'utils/entriesToObject';

class AnalyticsClass {
  private _startUrl: URL = new URL(window.location.href);
  private deviceParser: UAParser.UAParserInstance = new UAParser(
    window.navigator.userAgent,
  );
  constructor() {}

  init() {
    this.deviceParser = new UAParser(window.navigator.userAgent);
    this._startUrl = new URL(window.location.href);
  }

  get startParams() {
    let urlParams = new URLSearchParams(this._startUrl.search);
    return entriesToObject(urlParams.entries());
  }

  get startUTMParams() {
    let urlParams = new URLSearchParams(this._startUrl.search);

    const params: Record<string, string> = {};
    urlParams.forEach((val, key) => {
      if (key.startsWith('utm_')) {
        params[key] = val;
      }
    });
    return params;
  }

  get startHref() {
    return this._startUrl.href;
  }
  get startOrigin() {
    return this._startUrl.origin;
  }

  get deviceParams() {
    return {
      browser: this.deviceParser.getBrowser(),
      device: this.deviceParser.getDevice(),
      os: this.deviceParser.getOS(),
    };
  }
}

export const analitics = new AnalyticsClass();
