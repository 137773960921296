import styled from 'styled-components';

export const Container = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
`;

export const StyledIframe = styled.iframe`
  width: 100%;
  height: 100%;

  min-height: 700px;

  @media (hover: none) and (pointer: coarse) {
    min-height: fit-content;
  }
`;

export const SpinnerWrapper = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;
