import { SVGProps } from 'react';

export const PaymentWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg width={72} height={72} fill="none" {...props}>
    <path
      d="M72 36c0 19.882-16.118 36-36 36S0 55.882 0 36 16.118 0 36 0s36 16.118 36 36Z"
      fill="#FFC163"
      fillOpacity={0.2}
    />
    <path
      d="M64 36c0 15.464-12.536 28-28 28S8 51.464 8 36 20.536 8 36 8s28 12.536 28 28Z"
      fill="#FFC163"
    />
    <path
      d="M35.995 25.636a2.676 2.676 0 0 1 2.674 2.782l-.369 9.258a1 1 0 0 1-.999.96H34.69a1 1 0 0 1-1-.96l-.367-9.258a2.676 2.676 0 0 1 2.673-2.782Zm0 20.682a2.732 2.732 0 0 1-1.978-.805 2.66 2.66 0 0 1-.806-1.979 2.592 2.592 0 0 1 .806-1.939 2.732 2.732 0 0 1 1.978-.805c.73 0 1.372.268 1.93.805.563.537.848 1.183.854 1.94a2.678 2.678 0 0 1-.407 1.411 3 3 0 0 1-1.015 1.004 2.643 2.643 0 0 1-1.362.368Z"
      fill="#fff"
    />
  </svg>
);
