import { SVGProps } from 'react';
import { useTheme } from 'styled-components';

export const RightChevron = (props: SVGProps<SVGSVGElement>) => {
  const { colors } = useTheme();

  return (
    <svg
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      color={colors.accentMarsh}
      {...props}>
      <path
        d="M9 20l8-8-8-8"
        stroke="currentColor"
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
