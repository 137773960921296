export const darkColors = {
  //SYSTEM
  systemPrimary: '#FFFFFF',
  systemSecondary: '#000000',

  //BACKGROUND & SEPARATORS
  backgroundSeparatorsPrimary: '#141414',
  backgroundSeparatorsOppositePrimary: '#FFFFFF',
  backgroundSeparatorsSecondary: 'rgba(255, 255, 255, 0.05)',
  backgroundSeparatorsAdditional: '#FFFFFF',
  backgroundSeparatorsSurface: '#222222',
  backgroundSeparatorsSeparator: 'rgba(255, 255, 255, 0.1)',
  backgroundSeparatorsCream: 'rgba(248, 243, 232, 0.06)',

  //ICONS
  iconsAccent: '#2A8F83',
  iconsPrimary: 'rgba(255, 255, 255, 0.9)',
  iconsSecondary: 'rgba(255, 255, 255, 0.8)',
  iconsTertiary: 'rgba(255, 255, 255, 0.6)',
  iconsAdditional: 'rgba(255, 255, 255, 0.4)',
  iconsExtra: '#FFFFFF',

  //TEXT
  textAccent: '#2D998C',
  textPrimary: '#FFFFFF',
  textSecondary: 'rgba(255, 255, 255, 0.8)',
  textTertiary: 'rgba(255, 255, 255, 0.7)',
  textAdditional: 'rgba(255, 255, 255, 0.5)',
  textExtra: 'rgba(255, 255, 255, 0.2)',
  textInverterPrimary: '#000000',
  textInvertedSecondary: '#333333',
  textLink: '#2D998C',

  //ACCENT COLORS
  accentDarkMarsh: '#0B5B4F',
  accentMarsh: '#2A8F83',
  accentMint: '#BEE1D7',
  accentToxicBlue: '#0020F5',
  accentDarkPurple: '#3A398A',
  accentPurple: '#5F5ACE',
  accentViolet: '#9FA5F7',
  accentToxicSalad: '#D2FA5A',
  accentYello: '#F8DB80',

  //BUTTONS
  buttonsMarshAccent: '#23786E',
  buttonsMarshIntense: '#0A5046',
  buttonsGreyAccent: 'rgba(255, 255, 255, 0.1)',
  buttonsGreyIntense: 'rgba(255, 255, 255, 0.2)',
  buttonsSnowAccent: '#222222',
  buttonsSnowIntense: 'rgba(20, 20, 20, 0.4)',
  buttonsTextPrimary: '#FFFFFF',
  buttonsTextAdditional: '#FFFFFF',
  buttonsTextDisabled: 'rgba(255, 255, 255, 0.3)',
  buttonsIcon: '#FFFFFF',
  buttonsIconAdditional: '#FFFFFF',
  buttonsIconDisabled: 'rgba(255, 255, 255, 0.3)',
  buttonsDisabled: 'rgba(255, 255, 255, 0.1)',
  buttonsLink: '#2D998C',

  //INPUT
  inputTextPrimary: '#FFFFFF',
  inputTextSecondary: 'rgba(255, 255, 255, 0.7)',
  inputTextPlaceholder: 'rgba(255, 255, 255, 0.3)',
  inputLink: '#2D998C',
  inputStrokeDefault: 'rgba(255, 255, 255, 0.1)',
  inputStrokeFocus: '#FFFFFF',
  inputError: '#FF4747',
  inputCursor: '#2D998C',

  //GLYPH
  glyphFirst: '#FFFFFF',
  glyphSecond: '#F2F2F2',
  glyphThird: '#E6E6E6',
  glyphFourth: 'rgba(230, 230, 230, 0.9)',
  glyphFifth: 'rgba(230, 230, 230, 0.7)',
  glyphSixth: 'rgba(170, 170, 170, 0.5)',
  glyphSeventh: 'rgba(170, 170, 170, 0.3)',
  glyphEighth: 'rgba(170, 170, 170, 0.2)',
  glyphNinth: 'rgba(255, 255, 255, 0.09)',
  glyphFirstInverted: '#000000',

  //BUTTONS
  buttonFirst: 'rgba(255, 255, 255, 1)',
  buttonSecond: 'rgba(51, 51, 51, 0.8)',
  buttonThird: 'rgba(51, 51, 51, 0.8)',
};
