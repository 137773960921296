export const ShieldIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
    <path
      fill="#3DB83D"
      fillRule="evenodd"
      d="M4.166 4.062V11.672c0 2.61 1.533 3.562 4.348 5.31.461.287.957.595 1.485.934.529-.34 1.024-.647 1.485-.933 2.816-1.75 4.349-2.702 4.349-5.31V4.062c-2.28 0-3.649-.09-5.834-1.98-2.185 1.89-3.554 1.98-5.833 1.98Zm6.52 11.532-.687.429a295.26 295.26 0 0 0-.687-.428c-.364-.227-.695-.433-.949-.595-.71-.453-1.231-.817-1.626-1.168-.381-.34-.603-.632-.74-.923-.135-.288-.231-.667-.231-1.237V5.621a8.683 8.683 0 0 0 1.793-.301c.835-.24 1.623-.627 2.44-1.203.818.576 1.606.963 2.44 1.203a8.683 8.683 0 0 0 1.794.3v6.052c0 .57-.096.949-.232 1.237-.137.291-.358.584-.74.923-.394.35-.916.715-1.626 1.168-.253.162-.585.368-.948.594Zm.017-2.287c1.333-.828 2.06-1.28 2.06-2.515V7.187c-1.08 0-1.729-.042-2.764-.937v7.5c.25-.161.485-.307.704-.443Z"
      clipRule="evenodd"
    />
  </svg>
);
