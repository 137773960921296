import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  *, *:before, *:after {
    box-sizing: border-box;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
  }

  :root {
   --window-height: 100vh;
  }

  body {
    background: ${({ theme }) => theme.colors.backgroundSeparatorsPrimary};
    color: ${({ theme }) => theme.colors.textPrimary};
    ${({ theme }) => theme.fonts.subheadRegular};
    line-height: normal;

    &.no-scroll {
      overflow: hidden;
    }
  }

  button {
    border: none;
    transition: all 0.3s linear;
    cursor: pointer;
    padding: 0px;
    background: transparent;
  }

  a, a:visited { 
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textLink};
    cursor: pointer;
  }

  i {
    font-style: italic;
  }
`;
