/**
 * General process with client button https://medium.com/analytics-vidhya/paypal-subscription-in-react-1121c39b26be
 * how to cancel subscription in paypal account https://www.youtube.com/watch?v=QFnxBZOq-8I
 */

import React, { memo, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';

import { ISpinner } from 'components/Icons/ISpinner';

import { SpinnerWrapper } from './PaypalContainer.style';

declare global {
  interface Window {
    paypal: any;
  }
}

let isSdkReadyGlobal = false;

export interface PaypalOptions {
  clientId?: string;
  merchantId?: string;
  currency?: number | string;
  intent?: string;
  commit?: boolean | string;
  vault?: boolean | string;
  component?: string;
  disableFunding?: string;
  disableCard?: string;
  integrationDate?: string;
  locale?: string;
  buyerCountry?: string;
  debug?: boolean | string;
}

export type OnApproveData = {
  billingToken?: string | null;
  facilitatorAccessToken: string;
  orderID: string;
  payerID?: string | null;
  paymentID?: string | null;
  subscriptionID?: string | null;
  authCode?: string | null;
};
export type OnApproveActions = {
  order: {
    capture: () => Promise<any>;
    redirect: (redirectURL: string) => void;
    restart: () => void;
  };
};
export type OnApproveCallback = (
  data: OnApproveData,
  actions: OnApproveActions,
) => void;

export interface PayPalButtonProps {
  amount?: number | string;
  currency?: number | string;
  shippingPreference?: 'NO_SHIPPING' | 'GET_FROM_FILE' | 'SET_PROVIDED_ADDRESS';
  onSuccess?: Function;
  catchError?: Function;
  onError?: Function;
  createOrder?: Function;
  createSubscription?: Function;
  onApprove?: OnApproveCallback;
  style?: object;
  options?: PaypalOptions;
  onButtonReady?: Function;
  onClick?: Function;
  onCancel?: Function;
  // funding?: object,
}

const onApproveLocal = ({
  data,
  actions,
  onSuccess,
  catchError,
}: Pick<PayPalButtonProps, 'onSuccess' | 'catchError'> & {
  data: any;
  actions: any;
}) => {
  return actions.order
    .capture()
    .then((details: any) => {
      if (onSuccess) {
        return onSuccess(details, data);
      }
    })
    .catch((err: any) => {
      if (catchError) {
        return catchError(err);
      }
    });
};

export const PayPalButton = memo(
  ({
    onSuccess,
    catchError,
    onError,
    createSubscription,
    onApprove,
    style,
    options = {
      clientId: 'sb',
      currency: 'USD',
    },
    onButtonReady,
    onClick,
    onCancel,
  }: // funding,
  PayPalButtonProps) => {
    const [isSdkReady, setIsSdkReady] = useState(isSdkReadyGlobal);

    useEffect(() => {
      const loadPaypalSdk = () => {
        const queryParams: string[] = [];

        // replacing camelCase with dashes
        if (options) {
          Object.keys(options).forEach(k => {
            const name = k
              .split(/(?=[A-Z])/)
              .join('-')
              .toLowerCase();
            queryParams.push(`${name}=${options[k as keyof PaypalOptions]}`);
          });
        }

        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = `https://www.paypal.com/sdk/js?disable-funding=credit,card&${queryParams.join(
          '&',
        )}`;
        script.async = true;
        script.onload = () => {
          setIsSdkReady(true);
          isSdkReadyGlobal = true;

          if (onButtonReady) {
            onButtonReady();
          }
        };
        script.onerror = () => {
          throw new Error('Paypal SDK could not be loaded.');
        };

        document.body.appendChild(script);
      };

      if (
        !isSdkReady &&
        typeof window !== 'undefined' &&
        window !== undefined &&
        window.paypal === undefined
      ) {
        loadPaypalSdk();
      } else if (
        typeof window !== 'undefined' &&
        window !== undefined &&
        window.paypal !== undefined &&
        onButtonReady
      ) {
        onButtonReady();
      }
    }, [onButtonReady, options]);

    if (
      !isSdkReady ||
      typeof window === 'undefined' ||
      window.paypal === undefined
    ) {
      return (
        <SpinnerWrapper>
          <ISpinner size="large" />
        </SpinnerWrapper>
      );
    }

    const onApproveFn = onSuccess
      ? (data: any, actions: any) =>
          onApproveLocal({
            data,
            actions,
            onSuccess,
            catchError,
          })
      : (data: any, actions: any) => onApprove!(data, actions);

    const Button = window.paypal.Buttons.driver('react', {
      React,
      ReactDOM,
    });

    return (
      <Button
        createSubscription={createSubscription}
        onApprove={onApproveFn}
        style={style}
        onClick={onClick}
        onCancel={onCancel}
        onError={onError}
      />
    );
  },
);
