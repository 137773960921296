import { DefaultTheme } from 'styled-components';

import { colorsPalette } from './colors/common';
import { darkColors } from './colors/dark';
import { lightColors } from './colors/light';
import { fonts } from './fonts/fonts';
import { sizes } from './sizes/sizes';

export const darkTheme: DefaultTheme = {
  fonts,
  colors: {
    ...colorsPalette,
    ...darkColors,
  },
  sizes,
  isDark: true,
};

export const lightTheme: DefaultTheme = {
  fonts,
  colors: {
    ...colorsPalette,
    ...lightColors,
  },
  sizes,
  isDark: false,
};
