import React from 'react';
import { useTheme } from 'styled-components';

export const I16ArrowRight = (props: React.SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      color={theme.colors.iconsAdditional}
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.4142 8.70718L7.12132 13.0001C6.7308 13.3906 6.09763 13.3906 5.70711 13.0001C5.31658 12.6096 5.31658 11.9764 5.70711 11.5859L9.29289 8.00008L5.70711 4.41429C5.31658 4.02376 5.31658 3.3906 5.70711 3.00008C6.09763 2.60955 6.7308 2.60955 7.12132 3.00008L11.4142 7.29297C11.8047 7.68349 11.8047 8.31666 11.4142 8.70718Z"
        fill="currentColor"
      />
    </svg>
  );
};
