import React from 'react';
import { useTheme } from 'styled-components';

export const I24ArrowLeft = (props: React.SVGProps<SVGSVGElement>) => {
  const theme = useTheme();

  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      color={theme.colors.iconsPrimary}
      {...props}>
      <path
        d="M15 20L7 12L15 4"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
