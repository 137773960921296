import styled from 'styled-components';

export const Container = styled.div`
  margin: 0 24px 24px 24px;
`;

export const IconWrap = styled.div`
  margin-right: 8px;
`;

export const FeaturesWrapper = styled.ul`
  margin-bottom: 28px;
`;

export const FeatureContainer = styled.li`
  display: flex;
  margin-bottom: 8px;
`;

export const FeatureText = styled.p`
  ${({ theme }) => theme.fonts.headlineSemibold}

  color: ${({ theme }) => theme.colors.textTertiary}
`;

export const SpinnerWrapper = styled.div`
  width: fit-content;
  margin: 0 auto;
`;
