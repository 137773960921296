import { ICheck } from 'components/Icons/ICheck';

import {
  FeatureContainer,
  FeatureText,
  IconWrap,
} from './PaypalContainer.style';

interface Props {
  feature: string;
}

export const FeatureItem = ({ feature }: Props) => (
  <FeatureContainer key={feature}>
    <IconWrap>
      <ICheck />
    </IconWrap>
    <FeatureText>{feature}</FeatureText>
  </FeatureContainer>
);
