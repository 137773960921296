export const ICheck = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.05717 11.3602C8.59337 10.8868 7.83361 10.879 7.3602 11.3428C6.8868 11.8067 6.87902 12.5664 7.34283 13.0398L10.3428 16.1019C10.8337 16.6029 11.6479 16.5779 12.1071 16.0477L17.3571 9.98561C17.791 9.48463 17.7366 8.72678 17.2356 8.29291C16.7346 7.85904 15.9768 7.91344 15.5429 8.41443L11.1456 13.4919L9.05717 11.3602Z"
      fill="#15CE28"
    />
  </svg>
);
