import styled from 'styled-components';

import { ShieldIcon } from './ShieldIcon';

const Wrapper = styled.section<{ isDark?: boolean }>`
  display: flex;
  padding: 4px 8px 4px 8px;
  align-self: center;
  align-items: flex-end;
  border-radius: 8px;
  gap: 4px;

  background: ${({ isDark }) =>
    isDark ? 'rgba(61, 184, 61, 0.2)' : '#3db83d33'};
`;

const Text = styled.span`
  ${({ theme }) => theme.fonts.subheadMedium};

  color: ${({ theme }) => theme.colors.positive};
`;

type Props = {
  isDark?: boolean;
};

export const PaySafeBadge = ({ isDark }: Props) => {
  return (
    <Wrapper isDark={isDark}>
      <ShieldIcon />
      <Text>Pay safe & secure</Text>
    </Wrapper>
  );
};
