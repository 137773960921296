import { PaymentStates } from 'stores/Payment';

import { Icons } from 'components/Icons';
import { ISpinner } from 'components/Icons/ISpinner';

import { LoadingText, MessageWrap } from './Content.style';

interface Props {
  paymentState: PaymentStates;
}

export const Content = ({ paymentState }: Props) => {
  const loading = paymentState === PaymentStates.loading;
  const success = paymentState === PaymentStates.success;
  const error = paymentState === PaymentStates.error;

  if (loading) {
    return <ISpinner size="large" />;
  }

  if (success) {
    return (
      <MessageWrap>
        <Icons.PaymentSuccess />
        <LoadingText>Your payment was successful!</LoadingText>
      </MessageWrap>
    );
  }

  if (error) {
    return (
      <MessageWrap>
        <Icons.PaymentWarning />
        <LoadingText>
          Oops, something went wrong.{'\n'}Please, try again
        </LoadingText>
      </MessageWrap>
    );
  }

  return null;
};
