import styled, { css } from 'styled-components';

const flexCenter = css`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const stickyTop = css`
  position: sticky;
  top: 0;
`;

export const HeaderContainer = styled.div<{ isSticky?: boolean }>`
  ${({ isSticky }) => isSticky && stickyTop};

  width: 100%;
  background-color: ${({ theme }) => theme.colors.backgroundSeparatorsPrimary};
  z-index: 2;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 56px;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderTitle = styled.h1`
  ${({ theme }) => theme.fonts.title7Bold}

  font-family: 'Inter';
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;

  white-space: nowrap;
`;

export const TitleWrapper = styled.div`
  ${flexCenter};
  position: absolute;
  width: auto;
  left: 50%;
  transform: translate(-50%, -0%);
`;

export const LeftWrapper = styled.div`
  ${flexCenter};
  position: absolute;
  left: 20px;
`;

export const RightWrapper = styled.div`
  ${flexCenter};
  position: absolute;
  right: 20px;
`;

export const Line = styled.div`
  height: 2px;
  background-color: ${({ theme }) =>
    theme.colors.backgroundSeparatorsSeparator};
`;

export const BackButton = styled.button`
  ${flexCenter};

  &:hover {
    opacity: 0.5;
  }
`;
