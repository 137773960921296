export const IGoalBadge = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} fill="none">
      <g clipPath="url(#a)">
        <circle cx={10} cy={10} r={10} fill="#58B951" />
        <path
          fill="#fff"
          d="M10 5a8.463 8.463 0 0 0 5 5 8.463 8.463 0 0 0-5 5 8.463 8.463 0 0 0-5-5 8.463 8.463 0 0 0 5-5Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h20v20H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};
