import { LocationDescriptor } from 'history';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import { useSubrouteName } from './useSubrouteName';

export const useRelativeNavigation = <T>() => {
  const { push: basePush, replace: baseReplace } = useHistory<T>();
  const { subrouteName } = useSubrouteName();

  const push = useCallback(
    (pathArg: string | LocationDescriptor<T>, state?) => {
      if (typeof pathArg === 'object' && pathArg.pathname) {
        const pathname = `${subrouteName}${pathArg.pathname}`;

        basePush({
          ...pathArg,
          pathname,
        });
      } else {
        basePush(`${subrouteName}${pathArg}`, state);
      }
    },
    [basePush, subrouteName],
  );

  const replace = useCallback(
    (pathArg: string | LocationDescriptor<T>, state?) => {
      if (typeof pathArg === 'object' && pathArg.pathname) {
        baseReplace({
          ...pathArg,
          pathname: `${subrouteName}${pathArg.pathname}`,
        });
      } else {
        baseReplace(`${subrouteName}${pathArg}`, state);
      }
    },
    [baseReplace, subrouteName],
  );

  return { push, replace };
};
