import { observer } from 'mobx-react-lite';
import { useCallback, useRef } from 'react';

import { useMst } from 'stores/RootStore';

import { deferredCall } from 'utils/common';

import { InputCode, InputCodeRef } from 'components/InputCode/InputCode';
import { I } from 'components/primitives';

import { Subtitle, Title } from './AuthModule.styles';

interface Props {
  onSuccess: () => void;
}

export const MagicCode = observer(({ onSuccess }: Props) => {
  const { authStore } = useMst();
  const ref = useRef<InputCodeRef>(null);

  const handleError = useCallback(
    deferredCall(() => {
      authStore.clearError();
      ref.current?.reset();
    }, 2000),
    [],
  );

  const handleComplete = (code: string) => {
    authStore.verifyCode(code, onSuccess, handleError);
  };

  return (
    <>
      <Title variant="login">
        Eneter the <I>code</I>
      </Title>
      <Subtitle>
        We just sent a login code to {authStore.email} Check your inbox
        including Spam folder
      </Subtitle>
      <InputCode
        ref={ref}
        length={4}
        loading={authStore.isLoading}
        autoFocus
        onComplete={handleComplete}
        isError={authStore.isError}
        errorMessage={authStore.errorMessage}
      />
    </>
  );
});
