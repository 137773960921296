import styled from 'styled-components';

export const PaymentWrap = styled.div<{ showPayment: boolean }>`
  display: ${({ showPayment }) => (showPayment ? 'flex' : 'none')};
  flex: 1;
  justify-content: center;

  #payment-section {
    /* padding: 24px 0; */
    width: 100%;
  }
`;

export const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
`;

export const LoadingContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 600px;
  height: 100%;
`;

export const ButtonWrap = styled.div`
  position: absolute;
  width: 100%;
  @media only screen and (hover: none) and (pointer: coarse) {
    position: fixed;
  }
  bottom: 0;
  left: 0;

  padding: 20px;
`;
