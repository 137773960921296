export enum REMOTE_CONFIG_KEY {
  WEB_CONFIG_AB_OVERRIDE = 'web_config_ab_override',
  WEB_PRODUCT_IDS = 'web_product_ids',
  WEB_SELECTED_PRODUCT_ID = 'web_selected_product_id',
  WEB_ONBOARDING_TYPE = 'web_onboarding_type',
  WEB_PAYWALL_TYPE = 'web_paywall_type',
  WEB_SUCCESS_TYPE = 'web_success_type',
  WEB_LANDING_TYPE = 'web_landing_type',
  WEB_PAGES = 'web_pages',
  WEB_SUBSCRIPTION = 'web_subscription',
  MARKETING_PRICE = 'marketing_price',
  REVIEWS = 'reviews',
  SKIP_AUTH_PAYWALL_TYPES = 'skip_auth_paywall_types',
  WEB_PAYMENT_PROVIDERS = 'web_payment_providers',
  WEB_PAYPRO_CONFIG = 'web_paypro_config',
  SHOW_DISCOUNT_PAGE = 'show_discount_page',
  SHOW_TARIFF_DUPLICATE = 'show_tariff_duplicate',

  AFTER_PAYWALL_PRODUCT_DATA = 'after_paywall_product_data',
  AFTER_PAYWALL_PRODUCT_ID = 'after_paywall_product_id',
  AFTER_PAYWALL = 'after_paywall',
  WEB_AFTER_PAYWALL_TYPE = 'web_after_paywall_type',

  UPGRADE_PAYWALL_SUBSCRIPTIONS_IDS = 'web_upgrade_paywall_subscriptions_ids',
  UPGRADE_PAYWALL = 'web_upgrade_paywall',
  WEB_UPGRADE_TYPE = 'web_upgrade_type', //default | yearly | premium

  WEB_PRODUCT_HIDE_IDS = 'web_product_hide_ids',
  WEB_PREMIUM_UPGRADE_IDS = 'web_premium_upgrade_ids',
}

export const DEFAULT_SUBSCRIPTION_TYPE = 'alpha_subs';
