import { observer } from 'mobx-react-lite';
import { FC } from 'react';
import { Redirect, Route, RouteProps, useLocation } from 'react-router-dom';

import { useMst } from 'stores/RootStore';

import { APP_ROUTES } from 'navigations/Routs';

export enum RedirectConditions {
  CONFIRMED = 'confirmed',
  PAYMENT_APPROVED = 'paymentApproved',
  TOKEN = 'token',
}

interface IProtectedRoute extends RouteProps {
  condition: RedirectConditions | RedirectConditions[];
  redirectPath: APP_ROUTES;
  basePath?: string;
}

export const ProtectedRoute: FC<IProtectedRoute> = observer(
  ({ redirectPath, condition, children, basePath = '', ...rest }) => {
    const urlQuery = useLocation().search;
    const {
      authStore: { token, confirmed },
      paymentStore: { paymentApproved },
    } = useMst();

    const conditionsMap = {
      [RedirectConditions.CONFIRMED]: confirmed,
      [RedirectConditions.TOKEN]: !!token,
      [RedirectConditions.PAYMENT_APPROVED]: paymentApproved,
    };

    const shouldRenderChildren = Array.isArray(condition)
      ? condition.reduce((acc, curr) => acc && conditionsMap[curr], true)
      : conditionsMap[condition];

    const redirectTo = `${basePath}${redirectPath}${urlQuery}`;

    return (
      <Route {...rest}>
        {shouldRenderChildren ? children : <Redirect to={redirectTo} />}
      </Route>
    );
  },
);
