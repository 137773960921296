import styled, { css } from 'styled-components';

export const BaseStyledInput = styled.input`
  width: 100%;
  border: none;
  display: block;
  outline: none;

  ${({ theme }) => theme.fonts.headlineRegular};
  font-size: 16px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.inputTextPlaceholder};
  }
`;

const rightBorder = css`
  border-right: 2px solid
    ${({ theme }) => theme.colors.backgroundSeparatorsSeparator};
  margin-right: 12px;
`;

export const CardNumberWrap = styled.div`
  width: 100%;
`;

export const MultyInputRow = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const MultyInputItemWrap = styled.div<{ withBorderRight?: boolean }>`
  width: 33%;

  ${({ withBorderRight }) => withBorderRight && rightBorder}
`;

export const CardExpiryWrap = styled.div`
  width: 100%;
`;
