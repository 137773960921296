import { AppConfig } from 'config';

export const setWithExpiry = (key: string, value: any, ttl: number) => {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl,
  };
  window.localStorage.setItem(key, JSON.stringify(item));
};

export const getWithExpiry = (key: string) => {
  const itemStr = window.localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();

  if (now.getTime() > item.expiry) {
    window.localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

export const getAndSaveValue = (key: string, newValue: any) => {
  const value = getWithExpiry(key);
  if (!value) {
    setWithExpiry(key, newValue, 604800000);
    return newValue;
  } else {
    return value;
  }
};

export function checkVersion() {
  const prevVersion = window.localStorage.getItem('app_version');

  if (prevVersion !== AppConfig.APP_VERSION) {
    window.localStorage.clear();
    window.localStorage.setItem('app_version', AppConfig.APP_VERSION);
  }
}
