import FBPixel from 'react-facebook-pixel';
import ReactPinterestTag from 'react-pinterest-tag';
import SnapchatPixel from 'react-snapchat-pixel';
import TiktokPixel from 'tiktok-pixel';
import { initTwitter } from './twitter-pixel';
import { AppConfig } from 'config';

declare global {
  interface Window {
    twq?: Function;
  }
}

export const twitterPixelEventIds = {
  success: 'tw-o98ee-ojirn',
};

export enum PixelTrackEvents {
  /**
   * открытие онбординга
   */
  CONTENT_VIEW = 'ViewContent',

  /**
   * отправка почты в онбординге
   */
  REGISTRATION_COMPLETED = 'CompleteRegistration',

  /**
   * оформление триала
   **/
  PURCHASE = 'Purchase',

  /**
   * Дополнительная продажа
   **/
  ADDITIONAL_PURCHASE = 'AdditionalPurchase',

  /**
   * Смена подписки на дорогую
   **/
  UPGRADE_PURCHASE = 'UpgradePurchase',

  /**
   * Смена подписки на премиальную
   **/
  PREMIUM_UPGRADE_PURCHASE = 'PremiumUpgradePurchase',

  /**
   * ввод email
   */
  SNAP_SIGN_UP = 'SIGN_UP',

  /**
   * оформление подписки
   */
  SNAP_PURCHASE = 'PURCHASE',

  /**
   * Смена подписки на дорогую
   **/
  UPGRADE_SNAP_PURCHASE = 'UPGRADE_PURCHASE',

  /**
   * Смена подписки на премиальную
   **/
  PREMIUM_UPGRADE_SNAP_PURCHASE = 'PREMIUM_UPGRADE_PURCHASE',

  /**
   * Дополнительная продажа
   **/
  ADDITIONAL_SNAP_PURCHASE = 'ADDITIONAL_PURCHASE',

  /**
   * подтверждение email
   */
  SNAP_LOGIN = 'LOGIN',

  PINTEREST_LOGIN = 'lead',
  PINTEREST_CHECKOUT = 'checkout',
}

class PixelClass {
  init(ids: string[]) {
    ids.forEach(id => FBPixel.init(id));
    FBPixel.pageView();
  }

  track(event: PixelTrackEvents, data?: any) {
    FBPixel.track(event, data);
  }

  snapInit(ids: string[], advancedMatching?: object) {
    ids.forEach(id => SnapchatPixel.init(id, advancedMatching));
    SnapchatPixel.pageView();
  }

  pinterestInit(id: string, email?: string) {
    ReactPinterestTag.init(id, email);
    ReactPinterestTag.pageView();
  }

  pinterestPageView() {
    ReactPinterestTag.pageView();
  }

  pinterestTrack(event: PixelTrackEvents, data?: Record<string, any>) {
    ReactPinterestTag.track(event, data);
  }

  snapPageView() {
    SnapchatPixel.pageView();
  }

  snapTrack(event: PixelTrackEvents, data?: Record<string, any>) {
    SnapchatPixel.track(event, data);
  }

  tiktokInit(
    ids: string[],
    advancedMatching?: TiktokPixel.AdvancedMatching,
    options?: TiktokPixel.Options,
  ) {
    ids.forEach(id => {
      TiktokPixel.init(id, advancedMatching, options);
      TiktokPixel.pageView();
    });
  }

  twitterInit(pixel_id: string) {
    console.log(pixel_id);
    initTwitter();
  }

  twitterTrack<T extends Object>(event_id: string, body: T) {
    this.twitterInit(AppConfig.TWITTER_PIXEL_ID);
    if (window.twq) {
      window.twq('event', event_id, body);
    }
  }

  tiktokTrack(event: PixelTrackEvents, data?: Record<string, any>) {
    TiktokPixel.track(event, data);
  }
}

export const PixelServices = new PixelClass();
