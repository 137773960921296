import styled from 'styled-components';

import { ISpinner } from 'components/Icons/ISpinner';

export const LoadingView = () => {
  return (
    <Container>
      <ISpinner size="large" />
    </Container>
  );
};

const Container = styled.div`
  width: 100vw;
  height: 100vh;

  display: flex;
  justify-content: center;
  align-items: center;
`;
