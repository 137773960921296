import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from '@stripe/react-stripe-js';
import { FocusEvent, useState } from 'react';

import { ElementType, StripePaywallElements } from 'hooks/useStripePaywall';

import { InputRow } from 'components/InputRow/InputRow';

import {
  BaseStyledInput,
  CardNumberWrap,
  MultyInputItemWrap,
  MultyInputRow,
} from './FormInputs.style';

interface IFormProps extends StripePaywallElements {
  disabled?: boolean;
}

export const FormInputs = ({
  addressZipProps,
  stripeElementProps,
  inputError,
  disabled,
}: IFormProps) => {
  const [focused, setFocused] = useState<ElementType | null>(null);

  const expiryElementPropsOptions = {
    ...stripeElementProps,
    options: { ...stripeElementProps.options, placeholder: 'MM/YY' },
  };

  const handleFocus = (
    event: { elementType: string } | FocusEvent<HTMLInputElement>,
  ) => {
    if (
      'elementType' in event &&
      event.elementType === ElementType.CARD_NUMBER
    ) {
      setFocused(ElementType.CARD_NUMBER);
    } else {
      setFocused(ElementType.CARD_CVC);
    }
  };

  const handleBlur = () => {
    setFocused(null);
  };

  return (
    <>
      <InputRow
        isError={!!inputError}
        errorMessage={inputError?.message}
        isFocused={focused === ElementType.CARD_NUMBER}
        label="Card">
        <CardNumberWrap>
          <CardNumberElement
            onChange={stripeElementProps.onChange}
            options={{ ...stripeElementProps.options, disabled }}
            //@ts-ignore
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
        </CardNumberWrap>
      </InputRow>
      <InputRow label="Expires On" isFocused={focused === ElementType.CARD_CVC}>
        <MultyInputRow>
          <MultyInputItemWrap withBorderRight>
            <CardExpiryElement
              onChange={expiryElementPropsOptions.onChange}
              options={{ ...expiryElementPropsOptions.options, disabled }}
              //@ts-ignore
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </MultyInputItemWrap>
          <MultyInputItemWrap withBorderRight>
            <CardCvcElement
              onChange={stripeElementProps.onChange}
              options={{ ...stripeElementProps.options, disabled }}
              //@ts-ignore
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </MultyInputItemWrap>
          <MultyInputItemWrap>
            <BaseStyledInput
              {...addressZipProps}
              onChange={addressZipProps.onChange}
              disabled={disabled}
              name="postal-code"
              inputMode="numeric"
              placeholder="ZIP"
              maxLength={10}
              pattern="\d*"
              autoComplete="postal-code"
              required
              onFocus={handleFocus}
              onBlur={handleBlur}
            />
          </MultyInputItemWrap>
        </MultyInputRow>
      </InputRow>
    </>
  );
};
