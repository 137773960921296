import { observer } from 'mobx-react-lite';

import { useMst } from 'stores/RootStore';

import { getPricesBlockTitles } from 'pages/PaywallPage/utils/common';

import {
  BottomLabel,
  Container,
  DiscountText,
  GreenTopLabel,
  Row,
  Separator,
  TopLabel,
} from './PricesBlock.styles';

export const PricesBlock = observer(() => {
  const { paymentStore } = useMst();

  const product = paymentStore.productData;

  if (!product) {
    return null;
  }

  const { trial } = product;

  const {
    currencySymbol,
    oldPriceTitle,
    newPriceTitle,
    pricesDifferenceInNumber,
    pricesDifferenceInPercents,
  } = getPricesBlockTitles(trial, product);

  return (
    <Container>
      <Row withMargin>
        <TopLabel>Your personalized Senses Plan</TopLabel>
        <TopLabel>
          {currencySymbol}
          {oldPriceTitle}
        </TopLabel>
      </Row>
      <Row>
        <TopLabel>
          {pricesDifferenceInPercents} Introductory offer discount
        </TopLabel>
        <GreenTopLabel>
          - {currencySymbol}
          {pricesDifferenceInNumber}
        </GreenTopLabel>
      </Row>
      <Separator />
      <Row>
        <BottomLabel>Total:</BottomLabel>
        <BottomLabel>
          {currencySymbol}
          {newPriceTitle}
        </BottomLabel>
      </Row>
      <DiscountText>
        You saved {currencySymbol}
        {pricesDifferenceInNumber} ({pricesDifferenceInPercents} off)
      </DiscountText>
    </Container>
  );
});
