export const lightColors = {
  //SYSTEM
  systemPrimary: '#000000',
  systemSecondary: '#FFFFFF',

  //BACKGROUND & SEPARATORS
  backgroundSeparatorsPrimary: '#FFFFFF',
  backgroundSeparatorsOppositePrimary: '#141414',
  backgroundSeparatorsSecondary: 'rgba(0, 0, 0, 0.05)',
  backgroundSeparatorsAdditional: '#1F1F1F',
  backgroundSeparatorsSurface: '#FFFFFF',
  backgroundSeparatorsSeparator: 'rgba(0, 0, 0, 0.05)',
  backgroundSeparatorsCream: '#F8F3E8',

  //ICONS
  iconsAccent: '#23786E',
  iconsPrimary: '#333333',
  iconsSecondary: 'rgba(0, 0, 0, 0.6)',
  iconsTertiary: 'rgba(0, 0, 0, 0.4)',
  iconsAdditional: 'rgba(0, 0, 0, 0.2)',
  iconsExtra: '#FFFFFF',

  //TEXT
  textAccent: '#1E665D',
  textPrimary: '#000000',
  textSecondary: '#333333',
  textTertiary: 'rgba(0, 0, 0, 0.7)',
  textAdditional: 'rgba(0, 0, 0, 0.3)',
  textExtra: 'rgba(0, 0, 0, 0.2)',
  textInverterPrimary: '#FFFFFF',
  textInvertedSecondary: '#FFFFFF',
  textLink: '#23786E',

  //ACCENT COLORS
  accentDarkMarsh: '#0A5046',
  accentMarsh: '#23786E',
  accentMint: '#BEE1D7',
  accentToxicBlue: '#001EDC',
  accentDarkPurple: '#3A398A',
  accentPurple: '#5F5ACE',
  accentViolet: '#9FA5F7',
  accentToxicSalad: '#D2FA5A',
  accentYello: '#F8DB80',

  //BUTTONS
  buttonsMarshAccent: '#23786E',
  buttonsMarshIntense: '#0A5046',
  buttonsGreyAccent: 'rgba(0, 0, 0, 0.05)',
  buttonsGreyIntense: 'rgba(0, 0, 0, 0.1)',
  buttonsSnowAccent: '#FFFFFF',
  buttonsSnowIntense: 'rgba(0, 0, 0, 0.05)',
  buttonsTextPrimary: '#FFFFFF',
  buttonsTextAdditional: '#222222',
  buttonsTextDisabled: 'rgba(0, 0, 0, 0.2)',
  buttonsIcon: '#FFFFFF',
  buttonsIconAdditional: '#333333',
  buttonsIconDisabled: 'rgba(0, 0, 0, 0.2)',
  buttonsDisabled: 'rgba(0, 0, 0, 0.05)',
  buttonsLink: '#23786E',

  //INPUT
  inputTextPrimary: '#000000',
  inputTextSecondary: 'rgba(0, 0, 0, 0.7)',
  inputTextPlaceholder: 'rgba(0, 0, 0, 0.2)',
  inputLink: '#23786E',
  inputStrokeDefault: 'rgba(0, 0, 0, 0.05)',
  inputStrokeFocus: '#141414',
  inputError: '#FF4747',
  inputCursor: '#23786E',

  //GLYPH
  glyphFirst: '#000000',
  glyphSecond: '#141414',
  glyphThird: '#222222',
  glyphFourth: 'rgba(34, 34, 34, 0.9)',
  glyphFifth: 'rgba(34, 34, 34, 0.7)',
  glyphSixth: 'rgba(51, 51, 51, 0.5)',
  glyphSeventh: 'rgba(68, 68, 68, 0.3)',
  glyphEighth: 'rgba(119, 119, 119, 0.2)',
  glyphNinth: 'rgba(0, 0, 0, 0.03)',
  glyphFirstInverted: '#FFFFFF',

  //BUTTONS
  buttonFirst: 'rgba(31, 31, 31, 1)',
  buttonSecond: 'rgba(242, 242, 242, 0.8)',
  buttonThird: 'rgba(255, 255, 255, 1)',
};
