import styled from 'styled-components';

export const Text = styled.p`
  ${({ theme }) => theme.fonts.annotationRegular};
  text-align: center;

  margin-top: 16px;
  margin-bottom: 8px;
  max-width: 375px;

  color: ${({ theme }) => theme.colors.blackSixth};
`;
