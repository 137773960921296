import { formatPrice } from 'utils';

import { DATE_INTERVAL, IProductDescription } from 'types/types';

import { getCurrencySymbol } from 'utils/common';
import { pluralization } from 'utils/formaters';

interface PeriodTitleFormatIntervalProps {
  interval: DATE_INTERVAL;
  intervalCount: number;
}

export const getPeriodTitleFromInterval = ({
  interval,
  intervalCount,
}: PeriodTitleFormatIntervalProps) => {
  const title = {
    [DATE_INTERVAL.DAY]: `${intervalCount}-day plan`,
    [DATE_INTERVAL.WEEK]: `${intervalCount * 7}-day plan`,
    [DATE_INTERVAL.MONTH]: `${intervalCount}-month plan`,
    [DATE_INTERVAL.YEAR]: `${intervalCount}-year plan`,
  }[interval];

  return title;
};

export const getExtendedPeriodText = (
  interval: DATE_INTERVAL,
  intervalCount: number,
) => {
  const text = interval.toLowerCase();

  return pluralization(
    intervalCount,
    `${text}ly`,
    `each ${intervalCount} ${text + 's'}`,
  );
};

export const getFormattedInterval = ({
  interval,
  intervalCount,
}: PeriodTitleFormatIntervalProps) => {
  if (interval === DATE_INTERVAL.DAY) {
    return `${intervalCount}-days`;
  }

  if (interval === DATE_INTERVAL.WEEK) {
    const weekInterval = intervalCount === 1 ? '' : `${intervalCount}-`;
    return `${weekInterval}week`;
  }

  if (interval === DATE_INTERVAL.MONTH) {
    const monthInterval = intervalCount === 1 ? '' : `${intervalCount}-`;
    return `${monthInterval}month`;
  }

  if (interval === DATE_INTERVAL.YEAR) {
    const yearInterval = intervalCount === 1 ? '' : `${intervalCount}-`;
    return `${yearInterval}month`;
  }

  return '';
};

export const formatPlanDescription = ({
  interval,
  intervalCount,
  currency,
  amount,
}: IProductDescription) => {
  const periodTitle = `${intervalCount}-${interval.toLowerCase()}`;
  const priceTitle = `${getCurrencySymbol(currency)}${formatPrice(amount)}`;
  const extendedText = getExtendedPeriodText(interval, intervalCount);

  return `Extended ${extendedText} after ${periodTitle} intro offer
          <br/>
          at the full price of ${priceTitle}/${getFormattedInterval({
    interval,
    intervalCount,
  })}`;
};
