import { Instance, types } from 'mobx-state-tree';
import persist from 'mst-persist';
import { createContext, useContext } from 'react';

import { AuthStore } from 'stores/Auth';
import { OnBoardingStore } from 'stores/OnBoarding';
import { PaymentStore } from 'stores/Payment';

const RootModel = types.model({
  onBoardingStore: OnBoardingStore,
  authStore: AuthStore,
  paymentStore: PaymentStore,
});

export const createStore = async (): Promise<RootInstance> => {
  const onBoardingStore = OnBoardingStore.create();
  const authStore = AuthStore.create();
  const paymentStore = PaymentStore.create();

  const rootStore = RootModel.create({
    onBoardingStore,
    authStore,
    paymentStore,
  });

  await Promise.all([
    persist('onBoardingStore', onBoardingStore, {
      blacklist: ['isLoading', 'loaderFinished'],
    }),
    persist('authStore', authStore, {
      blacklist: ['isLoading'],
    }),
    persist('paymentStore', paymentStore, {
      whitelist: ['paymentApproved', 'afterPaywallPurchasingProducts'],
    }),
  ]);

  return rootStore;
};

export type RootInstance = Instance<typeof RootModel>;
const RootStoreContext = createContext<null | RootInstance>(null);

export const { Provider } = RootStoreContext;

export function useMst() {
  const store = useContext(RootStoreContext);
  if (store === null) {
    throw new Error('Store cannot be null, please add a context provider');
  }
  return store;
}
