import isObject from 'lodash/isObject';

export const convertObjWithId = <T extends { _id: string }>({
  _id,
  ...instance
}: T) => ({
  ...instance,
  id: _id,
});

const isObjWithObjectId = (x: Record<string, any>) =>
  x.hasOwnProperty('_id') && typeof x._id === 'string';

// const objIdNumberToStr = (x: Record<string, any>) =>
//   x.hasOwnProperty('id') && typeof x.id === 'number'
//     ? { ...x, id: x.id.toString() }
//     : x;

export const convertObjWithIdRecursive = (x: any): any => {
  if (Array.isArray(x)) {
    return x.map(convertObjWithIdRecursive);
  } else if (isObject(x)) {
    const converted = isObjWithObjectId(x)
      ? convertObjWithId(x as object & { _id: string })
      : convertObjWithId(x as object & { _id: string });

    const res = Object.entries(converted).reduce(
      (prev, [key, val]) => ({
        ...prev,
        [key]: convertObjWithIdRecursive(val),
      }),
      {},
    ) as Record<string, any>;

    return res;
  } else {
    return x;
  }
};
