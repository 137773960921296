import styled from 'styled-components';

export const Page = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  height: 100%;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.backgroundSeparatorsPrimary};

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`;
