import { useEffect } from 'react';
import { UAParser } from 'ua-parser-js';

export const useDetectDeviceDOM = () => {
  useEffect(() => {
    const body = document.body;
    const parser = new UAParser(window.navigator.userAgent);

    const os = parser.getOS().name?.replace(/ /gi, '');
    os && body.classList.add(os);

    const browser = parser.getBrowser().name?.replace(/ /gi, '');
    browser && body.classList.add(browser);
  }, []);
};
