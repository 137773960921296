import { AnimatePresence } from 'framer-motion';
import {
  forwardRef,
  ReactNode,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Backdrop, ModalContainer } from './Popup.styles';

export type PopupCloseHandler = () => void;

export interface PopupRef {
  open: () => void;
  close: () => void;
  setCloseHandler: (handler: PopupCloseHandler) => void;
  setNode: (node: ReactNode) => void;
  openContent: (node: ReactNode) => void;
  applyStyles: (styles: string) => void;
}

export const PopupComponent = forwardRef<PopupRef>((_, ref) => {
  const [open, setOpen] = useState(false);
  const [appliedStyles, setAppliedStyles] = useState('');
  const childrenRef = useRef<ReactNode>(null);
  const closeHandlerRef = useRef<PopupCloseHandler | null>(null);

  useImperativeHandle(ref, () => ({
    open: () => {
      setOpen(true);
    },
    close: () => {
      setOpen(false);
    },
    setNode: (node: ReactNode) => {
      childrenRef.current = node;
    },
    setCloseHandler: (handler: PopupCloseHandler) => {
      closeHandlerRef.current = handler;
    },
    openContent: (node: ReactNode) => {
      childrenRef.current = node;
      setOpen(true);
    },
    applyStyles: (styles: string) => {
      setAppliedStyles(styles);
    },
  }));

  useEffect(() => {
    if (open) {
      document.querySelector('body')?.classList.add('no-scroll');
    } else {
      document.querySelector('body')?.classList.remove('no-scroll');
      closeHandlerRef.current?.();
      childrenRef.current = null;
      setAppliedStyles('');
      closeHandlerRef.current = null;
    }
  }, [open]);

  const handleClose = () => setOpen(false);

  return (
    <AnimatePresence exitBeforeEnter={true}>
      {open && (
        <Backdrop onClick={handleClose}>
          <ModalContainer
            additionalStyles={appliedStyles}
            onClick={e => e.stopPropagation()}>
            {childrenRef.current}
          </ModalContainer>
        </Backdrop>
      )}
    </AnimatePresence>
  );
});
