import styled from 'styled-components';

import { TextButton } from 'components/TextButton/TextButton';

interface IInputWrap {
  isError?: boolean;
  focused?: boolean;
}

interface IClear {
  isVisible?: boolean;
}

export const Container = styled.div``;

export const InputWrap = styled.div<IInputWrap>`
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  border-radius: 12px;
  border: 2px solid
    ${({ focused, isError, theme }) =>
      isError
        ? theme.colors.inputError
        : focused
        ? theme.colors.inputStrokeFocus
        : theme.colors.inputStrokeDefault};

  transition: all 0.15s ease-out;
`;

export const Label = styled.p`
  margin-top: 4px;
  margin-bottom: 6px;
  ${({ theme }) => theme.fonts.bodyBold};
  color: ${({ theme }) => theme.colors.textAdditional};
`;

export const StyledTextInput = styled.input<IInputWrap>`
  padding: 13px 20px 15px 20px;
  width: 100%;

  background-color: transparent;

  border-radius: 12px;

  outline: none;
  border: none;

  ${({ theme }) => theme.fonts.headlineRegular}

  @supports (-webkit-overflow-scrolling: touch) {
    /* safari autozoom fix: https://stackoverflow.com/a/16255670 */
    font-size: 16px;
  }

  color: ${({ theme }) => theme.colors.inputTextPrimary};
  &::placeholder {
    color: ${({ theme }) => theme.colors.inputTextPlaceholder};
  }
`;

export const ClearButton = styled(TextButton)<IClear>`
  ${({ theme }) => theme.fonts.headlineRegular};
  padding: 0;
  padding-right: 20px;

  color: ${({ theme }) => theme.colors.inputLink};

  transition: opacity 0.2s linear;
  opacity: ${({ isVisible }) => (isVisible ? 1 : 0)};
  cursor: ${({ isVisible }) => (isVisible ? 'pointer' : 'text')};
`;

export const DescriptionText = styled.p`
  ${({ theme }) => theme.fonts.subheadRegular}
  color: ${({ theme }) => theme.colors.inputTextSecondary};
  padding: 4px 20px;
`;

export const ErrorText = styled.p`
  padding: 4px 20px;
  ${({ theme }) => theme.fonts.subheadRegular}
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.inputError};
`;
