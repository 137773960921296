import { motion } from 'framer-motion';
import styled from 'styled-components';

export const LoadingText = styled(motion.p).attrs({
  initial: { opacity: 0, y: 20 },
  animate: { opacity: 1, y: 0 },
  transition: { delay: 0.75 },
})`
  margin-top: 24px;

  ${({ theme }) => theme.fonts.title7Semibold};
  text-align: center;
`;

export const MessageWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
