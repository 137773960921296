import styled, { useTheme } from 'styled-components';

const SPINNER_SIZES = {
  small: 17,
  medium: 24,
  large: 50,
};

interface Props {
  color?: string;
  size?: 'small' | 'medium' | 'large';
  className?: string;
}

export const ISpinner = ({ size = 'medium', ...props }: Props) => {
  const theme = useTheme();

  const spinnerSize = SPINNER_SIZES[size];

  return (
    <StyledSvg
      width={spinnerSize}
      height={spinnerSize}
      viewBox="0 0 25 24"
      fill="none"
      color={theme.colors.marshGreen}
      {...props}>
      <path
        d="M12.5 3a9 9 0 11-8.56 6.219"
        stroke="currentColor"
        strokeWidth={3}
        strokeLinecap="round"
      />
    </StyledSvg>
  );
};

const StyledSvg = styled.svg`
  animation: rotating 1s linear infinite;

  @keyframes rotating {
    100% {
      transform: rotate(1turn);
    }
  }
`;
