import { RefObject } from 'react';

import { PopupCloseHandler, PopupRef } from './PopupComponent';

class PopupClass {
  ref: RefObject<PopupRef> = { current: null };

  applyRef = (ref: RefObject<PopupRef>) => {
    this.ref = ref;
  };

  open = () => {
    this.ref.current?.open();
  };

  close = () => {
    this.ref.current?.close();
  };

  applyStylesToContainer = (styles: string) => {
    this.ref.current?.applyStyles(styles);
  };

  openContent = (props: PopupRef['openContent']) => {
    this.ref.current?.openContent(props);
  };

  setNode = (props: PopupRef['setNode']) => {
    this.ref.current?.setNode(props);
  };

  setCloseHandler = (handler: PopupCloseHandler) => {
    this.ref.current?.setCloseHandler(handler);
  };
}

export const Popup = new PopupClass();
