import React, { useState } from 'react';
import styled from 'styled-components';

interface Props {
  children: (pressed: boolean) => React.ReactNode | React.ReactNode;
  onPress?: (_?: unknown) => void;
  style?: React.CSSProperties;
}

const BaseStyle = styled.div`
  width: 100%;
  flex-grow: 1;

  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

export const Pressable = ({ children, style }: Props) => {
  const [pressed, setPressed] = useState(false);

  return (
    <BaseStyle
      style={style}
      onTouchStart={() => setPressed(true)}
      onTouchEnd={() => setPressed(false)}>
      {typeof children === 'function' ? children(pressed) : children}
    </BaseStyle>
  );
};
