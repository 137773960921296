import { useElements, useStripe } from '@stripe/react-stripe-js';
import {
  StripeCardNumberElementOptions,
  StripeElementChangeEvent,
} from '@stripe/stripe-js';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { useTheme } from 'styled-components';

import { StripeService } from 'services/stripeService';

import { FONTS } from 'constants/common';

export enum ElementType {
  CARD_NUMBER = 'cardNumber',
  CARD_EXPIRY = 'cardExpiry',
  CARD_CVC = 'cardCvc',
}

type StripeElementChangeEventError = {
  type: 'validation_error';
  code: string;
  message: string;
};

export const useStripePaywall = () => {
  const [inputError, setInputError] = useState<StripeElementChangeEventError>();
  const [addressZip, setAddressZip] = useState('');
  const addressZipRef = useRef<HTMLInputElement>(null);
  const stripeElements = useElements();
  const stripe = useStripe();
  const theme = useTheme();

  useEffect(() => {
    if (stripe && stripeElements) {
      StripeService.setStripeElementsRef(stripeElements);
      StripeService.setStripeRef(stripe);
    }
  }, [stripeElements, stripe]);

  const handleChangeAddressZip = (e: ChangeEvent<HTMLInputElement>) => {
    setAddressZip(e.target.value);
  };

  const addressZipProps = {
    value: addressZip,
    onChange: handleChangeAddressZip,
    ref: addressZipRef,
  };

  const options: StripeCardNumberElementOptions = useMemo(
    () => ({
      style: {
        base: {
          color: theme.colors.inputTextPrimary,
          fontFamily: FONTS.INTER,
          fontSize: '16px',
          fontWeight: '400',
          fontStyle: 'normal',
          '::placeholder': {
            color: theme.colors.inputTextPlaceholder,
            fontSize: '16px',
          },
        },
        invalid: {
          color: theme.colors.inputTextPrimary,
        },
      },
    }),
    [theme, FONTS],
  );

  const stripeElementProps = {
    options,
    onChange(event: StripeElementChangeEvent) {
      if (stripeElements && event.complete) {
        const type = event.elementType;
        if (type === ElementType.CARD_NUMBER) {
          stripeElements.getElement(ElementType.CARD_EXPIRY)?.focus();
        } else if (type === ElementType.CARD_EXPIRY) {
          stripeElements.getElement(ElementType.CARD_CVC)?.focus();
        } else if (type === ElementType.CARD_CVC) {
          addressZipRef.current?.focus();
        }
      }
      if (event.error) {
        console.log('event.error', event.error);
        setInputError(event.error);
      } else if (inputError) {
        setInputError(undefined);
      }
    },
  };

  return {
    stripeElementProps,
    addressZipProps,
    inputError,
  };
};

export type StripePaywallElements = ReturnType<typeof useStripePaywall>;
