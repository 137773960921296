import styled from 'styled-components';

export const Center = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -100%);
`;

export const LoadingContainer = styled.div`
  position: relative;
  width: 100%;
  min-height: 600px;
  height: 100%;
`;
