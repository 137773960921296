import upperFirst from 'lodash/upperFirst';
import { formatPrice } from 'utils';

import { IProductDescription } from 'types/types';

import { getCurrencySymbol } from 'utils/common';

export const getPaypalFeatures = (product?: IProductDescription) => {
  if (!product) {
    return [];
  }

  const { trial } = product;

  const features = [
    'After each payment a receipt will be sent to your e-mail',
    'Cancel anytime',
  ];

  if (trial) {
    const currencySymbol = getCurrencySymbol(trial.currency);
    const price = formatPrice(trial.amount);
    const parsedInterval = upperFirst(trial.interval.toLowerCase());

    const trialFeature = `${trial.intervalCount}-${parsedInterval} trial for ${currencySymbol}${price}`;

    features.unshift(trialFeature);
  }

  return features;
};
