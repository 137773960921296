import { GradientLogo } from './GradientLogo';
import { I16ArrowRight } from './I16ArrowRight';
import { ICross } from './ICross';
import { ISmallStar } from './ISmallStar';
import { LeftChevron } from './LeftChevron';
import { MoneyBack } from './MoneyBack';
import { PaymentSuccess } from './PaymentSuccess';
import { PaymentWarning } from './PaymentWarning';
import { Quote } from './Quote';
import { RightChevron } from './RightChevron';
import { IGoalBadge } from './IGoalBadge';

export const Icons = {
  LeftChevron,
  RightChevron,
  Quote,
  I16ArrowRight,
  ISmallStar,
  GradientLogo,
  ICross,
  MoneyBack,
  PaymentSuccess,
  PaymentWarning,
  IGoalBadge,
};
