import { useHistory } from 'react-router';

import { I24ArrowLeft } from 'components/Icons/I24ArrowLeft';

import { BackButton } from './PageHeader.styles';

export const LeftContent = () => {
  const { goBack, ...history } = useHistory();

  if (history.length <= 1) {
    return null;
  }

  return (
    <BackButton onClick={goBack}>
      <I24ArrowLeft />
    </BackButton>
  );
};
