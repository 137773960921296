import React from 'react';

import { Container, ErrorMessage, InputWrapper, Label } from './InputRow.style';

interface IInputRow {
  label?: string;
  name?: string;
  className?: string;
  isError?: boolean;
  isFocused?: boolean;
  errorMessage?: string;
}

export const InputRow: React.FC<IInputRow> = ({
  label,
  name,
  className,
  children,
  isError,
  errorMessage,
  isFocused,
}) => {
  return (
    <Container className={className}>
      {label && <Label htmlFor={name}>{label}</Label>}
      <InputWrapper isFocused={isFocused} isError={isError}>
        {children}
      </InputWrapper>
      {!!errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
    </Container>
  );
};
