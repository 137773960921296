import upperFirst from 'lodash/upperFirst';

import { DATE_INTERVAL, IProductDescription } from 'types/types';

import { pluralization } from 'utils/formaters';

interface GetTextFromProductProps {
  interval: DATE_INTERVAL;
  intervalCount: number;
  end: string;
}

const getTextFromProduct = ({
  interval,
  intervalCount,
  end,
}: GetTextFromProductProps) => {
  const parsedInterval = upperFirst(interval.toLowerCase());

  const pluralizedInterval = pluralization(
    intervalCount,
    parsedInterval,
    `${parsedInterval}s`,
  );

  const text = `Start ${intervalCount} ${pluralizedInterval} ${end}`;

  return text;
};

export const getStripeButtonText = (product?: IProductDescription) => {
  if (!product) {
    return '';
  }

  return getTextFromProduct(
    product.trial
      ? { ...product.trial, end: 'Trial' }
      : { ...product, end: 'Plan' },
  );
};
