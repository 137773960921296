import { Button } from 'components/Button/Button';
import { ICross } from 'components/Icons/ICross';
import { Logo } from 'components/Icons/Logo';
import { Page } from 'components/Layout/Layout.style';
import { PaySafeBadge } from 'components/PaySafeBadge/PaySafeBadge';

import { DiscountInfo } from '../../types';
import { Disclaimer } from '../Disclaimer/Disclaimer';
import { DiscountBanner } from '../DiscountBanner/DiscountBanner';
import { DiscountCard } from '../DiscountCard/DiscountCard';
import { DiscountExpiration } from '../DiscountExpiration/DiscountExpiration';

import { CloseButtonWrap, Content, LogoWrapper } from './DiscountPage.styles';

type Props = {
  time: string;
  discountAmount: number;
  discountInfo: DiscountInfo;
  onBuyWithDiscountPress: () => void;
  onClose: () => void;
};

export const DiscountPageStateless = ({
  time,
  discountAmount,
  discountInfo,
  onBuyWithDiscountPress,
  onClose,
}: Props) => {
  return (
    <Page>
      <CloseButtonWrap onClick={onClose}>
        <ICross />
      </CloseButtonWrap>
      <LogoWrapper>
        <Logo />
      </LogoWrapper>
      <DiscountExpiration time={time} />
      <DiscountBanner amount={discountAmount} />
      <Content>
        <DiscountCard {...discountInfo} />
        <Button onClick={onBuyWithDiscountPress}>Buy with discount</Button>
        <Disclaimer fullPrice={discountInfo.priceBeforeDiscount} />
        <PaySafeBadge />
      </Content>
    </Page>
  );
};
