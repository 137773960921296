import { SVGProps } from 'react';

export const PaymentSuccess = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={72}
    height={72}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M72 36c0 19.882-16.118 36-36 36S0 55.882 0 36 16.118 0 36 0s36 16.118 36 36Z"
      fill="#257E74"
    />
    <path
      d="M64 36c0 15.464-12.536 28-28 28S8 51.464 8 36 20.536 8 36 8s28 12.536 28 28Z"
      fill="#257E74"
    />
    <path
      d="M43.4 30.6c-.8-.8-2-.8-2.8 0L34 37.2l-2.6-2.6c-.8-.8-2-.8-2.8 0-.8.8-.8 2 0 2.8l4 4c.4.4.8.6 1.4.6.6 0 1-.2 1.4-.6l8-8c.8-.8.8-2 0-2.8Z"
      fill="#fff"
    />
  </svg>
);
