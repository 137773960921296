import { observer } from 'mobx-react-lite';
import { Suspense, useEffect, useRef } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import { useMst } from 'stores/RootStore';

import { Amplitude } from 'services/amplitude';
import { FirebaseService } from 'services/remoteConfig';

import { useRestoreQueryParams } from 'hooks/useRestoreQueryParams';

import { AnalyticalPage } from 'components/AnalyticalPage/AnalyticalPage';
import { LoadingView } from 'components/LoadingView/LoadingView';
import { Popup, PopupComponent, PopupRef } from 'components/Popup';

import { NestedNavigator } from './NestedNavigator';

export const AppNavigator = observer(() => {
  useRestoreQueryParams();
  const { paymentStore, authStore } = useMst();
  const popupRef = useRef<PopupRef>(null);

  useEffect(() => {
    Popup.applyRef(popupRef);
  }, []);

  useEffect(() => {
    async function init() {
      await Promise.all([
        paymentStore.getPaywallProducts(),
        paymentStore.getAllSubscriptions(),
        paymentStore.payproAdditionalProducts(),
      ]);
    }
    init();
  }, [authStore.token, authStore.confirmed]);

  useEffect(() => {
    if (authStore.token) {
      const accountId = authStore.accountId;

      Amplitude.setUserId(accountId);
    } else {
      Amplitude.setUserId(null);
    }
  }, [authStore.token]);

  useEffect(() => {
    if (paymentStore.validSubscriptions) {
      paymentStore.getSubscriptionProducts();
    }
  }, [paymentStore.validSubscriptions]);

  return (
    <Router>
      <Suspense fallback={<LoadingView />}>
        <Switch>
          {FirebaseService.pages.map((pageName: string) => (
            <Route key={pageName} path={`/${pageName}`}>
              <NestedNavigator />
            </Route>
          ))}
        </Switch>
      </Suspense>
      <AnalyticalPage />
      <PopupComponent ref={popupRef} />
    </Router>
  );
});
