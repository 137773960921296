import { CardElement, CardNumberElement } from '@stripe/react-stripe-js';
import {
  CreateTokenCardData,
  loadStripe,
  Stripe,
  StripeCardElement,
  StripeCardNumberElement,
  StripeElements,
  Token,
} from '@stripe/stripe-js';

import { AppConfig } from 'config';

export interface CardInfo {
  cardData: 'CardElement' | 'CardNumberElement';
  name?: string;
  addressZip: string;
  addressCountry?: string;
}

class StripeServiceClass {
  stripeRef: Stripe | null = null;
  stripeElementsRef: StripeElements | null = null;

  get stripeAppPromise() {
    return loadStripe(AppConfig.STRIPE_PUBLISHABLE_KEY ?? '');
  }

  setStripeRef(ref: Stripe) {
    this.stripeRef = ref;
  }

  setStripeElementsRef(ref: StripeElements) {
    this.stripeElementsRef = ref;
  }

  /**
   * Gets and returns stripe card element that is within Elements provider.
   * If there is no one then throws exception.
   * @param cardData
   */
  getCardElement(
    cardData: 'CardElement' | 'CardNumberElement',
  ): StripeCardElement | StripeCardNumberElement {
    try {
      const stripeCardElement =
        cardData === 'CardElement'
          ? this.stripeElementsRef!.getElement(CardElement)
          : this.stripeElementsRef!.getElement(CardNumberElement);

      if (!stripeCardElement) {
        throw new Error(
          'STRIPE: No CardElement is rendered in the current Elements provider tree.',
        );
      }

      return stripeCardElement;
    } catch (ex) {
      console.error(ex);
      throw ex;
    }
  }

  async createCardToken(
    stripeCardElement: StripeCardElement | StripeCardNumberElement,
    cardInfo: CardInfo,
  ): Promise<Token> {
    try {
      let data: CreateTokenCardData = {
        address_zip: cardInfo.addressZip,
        address_country: cardInfo.addressCountry,
        name: cardInfo.name,
      };

      const { token, error: tokenError } = await this.stripeRef!.createToken(
        stripeCardElement,
        data,
      );

      if (tokenError || !token) {
        const msg =
          tokenError?.message ||
          'STRIPE: Error in the payment data form. Can not create token.';
        throw new Error(msg);
      }

      return token;
    } catch (ex) {
      console.error(ex);
      throw ex;
    }
  }

  async createPaymentMethod(cardTokenId: string) {
    try {
      const { paymentMethod, error } =
        await this.stripeRef!.createPaymentMethod({
          type: 'card',
          card: {
            token: cardTokenId,
          },
          //card: stripeCardElement,
        });

      if (error || !paymentMethod) {
        const msg = error?.message || 'STRIPE: Error in the payment data form.';
        throw new Error(msg);
      }

      return paymentMethod;
    } catch (ex) {
      console.error(ex);
      throw ex;
    }
  }

  /**
   * Creates payment method.
   * If smth fails then throws exception.
   */
  async getPaymentMethodWithCard(cardInfo: CardInfo): Promise<string> {
    // 1. DEFINE CARD ELEMENT WE USE TO GET PAYMENT DATA.
    // if there is no one then throws exception.
    const cardElement = this.getCardElement(cardInfo.cardData);

    // 2. CREATE CARD TOKEN
    // if there is an error then throws exception
    const cardToken = await this.createCardToken(cardElement, cardInfo);

    // 3. CREATE PAYMENT METHOD ON STRIPE SERVER
    // thows an error if there is no paymentMethod
    const paymentMethod = await this.createPaymentMethod(cardToken.id);

    return paymentMethod.id;
  }
}

export const StripeService = new StripeServiceClass();
