import range from 'lodash/range';
import {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { Container, ErrorText, Input, InputsWrap } from './InputCode.style';

interface IInputCode {
  length: number;
  loading: boolean;
  autoFocus?: boolean;
  isError?: boolean;
  errorMessage?: string;
  onComplete: (value: string) => void;
}

export interface InputCodeRef {
  reset: () => void;
}

export const InputCode = forwardRef<InputCodeRef, IInputCode>(
  (
    { length, loading, onComplete, autoFocus, isError = false, errorMessage },
    handlerRef,
  ) => {
    const [code, setCode] = useState(range(length).map(() => ''));
    const inputs = useRef<(HTMLInputElement | null)[]>([]);
    errorMessage;

    useImperativeHandle(handlerRef, () => ({
      reset: () => {
        setCode(prev => prev.map(_ => ''));
        inputs.current[0]?.focus();
      },
    }));

    const processInput = (e: ChangeEvent<HTMLInputElement>, slot: number) => {
      const { value } = e.target;
      if (/[^0-9]/.test(value)) {
        return;
      }
      const newCode = [...code];
      newCode[slot] = value;
      setCode(newCode);
      if (slot !== length - 1) {
        inputs.current[slot + 1]?.focus();
      }
      if (newCode.every(num => num !== '')) {
        onComplete(newCode.join(''));
      }
    };

    const onKeyUp = (e: KeyboardEvent<HTMLInputElement>, slot: number) => {
      if (e.keyCode === 8 && !code[slot] && slot !== 0) {
        const newCode = [...code];
        newCode[slot - 1] = '';
        setCode(newCode);
        inputs.current[slot - 1]?.focus();
      }
    };

    return (
      <Container>
        <InputsWrap>
          {code.map((num, idx) => {
            return (
              <Input
                isError={isError}
                key={idx}
                type="text"
                autoComplete="none"
                inputMode="numeric"
                maxLength={1}
                value={num}
                autoFocus={autoFocus && !code[0].length && idx === 0}
                readOnly={loading}
                onChange={e => processInput(e, idx)}
                onKeyUp={e => onKeyUp(e, idx)}
                ref={ref => inputs.current.push(ref)}
              />
            );
          })}
        </InputsWrap>
        {!!errorMessage && <ErrorText>{errorMessage}</ErrorText>}
      </Container>
    );
  },
);
