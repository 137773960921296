import { Text } from './Disclaimer.styles';

type Props = {
  fullPrice: string;
};

export const Disclaimer = ({ fullPrice }: Props) => {
  return (
    <Text>
      {`We’ve automatically applied discount to your first subscription price. Please note that your subscription will be automatically renewed at full price of ${fullPrice} at the end of chosen subscription term.`}
    </Text>
  );
};
