export const sizes = {
  xsm: '320px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
  smSize: 576,
  mdSize: 768,
  lgSize: 992,
  xlSize: 1200,
  xxlSize: 1400,
};
