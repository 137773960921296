import { useEffect, useState } from 'react';

import { buildPayproParams, BuildPayproProps } from 'utils/buildPayproParams';

import { ISpinner } from 'components/Icons/ISpinner';

import { usePayproPurchase } from './hooks/usePayproPurchase';

import {
  Container,
  SpinnerWrapper,
  StyledIframe,
} from './PayproContainer.style';

type Props = {
  config: BuildPayproProps;
  handlePaymentSuccess: () => void;
  handlePaymentWaiting: () => void;
};

export const PayproContainer = ({
  config,
  handlePaymentSuccess,
  handlePaymentWaiting,
}: Props) => {
  const [loading, setLoading] = useState(true);
  const [payproSrc, setPayproSrc] = useState('');
  usePayproPurchase({ handlePaymentSuccess, handlePaymentWaiting });

  useEffect(() => {
    const _src = buildPayproParams(config);

    setPayproSrc(_src);
  }, []);

  return (
    <Container>
      {loading && (
        <SpinnerWrapper>
          <ISpinner size="large" />
        </SpinnerWrapper>
      )}
      {Boolean(payproSrc) && (
        <StyledIframe src={payproSrc} onLoad={() => setLoading(false)} />
      )}
    </Container>
  );
};
