import { DiscountInfo } from 'pages/DiscountPage/types';
import {
  CardWrapper,
  Column,
  Row,
  TextDiscountedPrice,
  TextFullPrice,
  TextOldPrice,
  TextPlan,
} from './DiscountCard.styles';

type Props = DiscountInfo;

export const DiscountCard = ({
  plan,
  priceAfterDiscount,
  pricePerDayAfterDiscount,
  pricePerDayBeforeDiscount,
}: Props) => {
  return (
    <CardWrapper>
      <Column>
        <TextPlan>{plan}</TextPlan>
        <TextFullPrice>{`${priceAfterDiscount}`}</TextFullPrice>
      </Column>
      <Row>
        <TextOldPrice>{`${pricePerDayBeforeDiscount}`}</TextOldPrice>
        <TextDiscountedPrice>{`${pricePerDayAfterDiscount} / day`}</TextDiscountedPrice>
      </Row>
    </CardWrapper>
  );
};
