import styled, { css } from 'styled-components';

import { Button } from 'components/Button/Button';

interface ICardLayout {
  cardLayout: boolean;
}

const borderLayout = css`
  border: 2px solid ${({ theme }) => theme.colors.backgroundSeparatorsSeparator};
`;

export const FormContainer = styled.div<ICardLayout>`
  width: 100%;

  padding: 16px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${({ cardLayout }) => cardLayout && borderLayout}
  border-radius: 16px;
`;

export const FormContainerTopBlock = styled.div<ICardLayout>`
  height: 52px;
  width: 100%;

  ${({ cardLayout }) =>
    cardLayout ? 'padding: 12px 20px' : 'padding: 12px 0'};

  display: flex;
  justify-content: space-between;
  align-items: center;

  border-bottom: 2px solid
    ${({ theme }) => theme.colors.backgroundSeparatorsSeparator};
`;

export const FormContainerTopText = styled.span`
  ${({ theme }) => theme.fonts.headlineBold};
`;

export const FormContainerBottomBlock = styled.div<ICardLayout>`
  ${({ cardLayout }) =>
    cardLayout ? 'padding: 15px 20px 24px;' : 'padding: 15px 0 24px'};
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

export const DisabledButtonWrap = styled.div<ICardLayout>`
  display: flex;
  column-gap: 8px;

  width: 100%;

  padding: 0 ${({ cardLayout }) => (cardLayout ? 0 : 16)}px;
`;

export const DisabledButton = styled(Button)`
  padding: 8px 16px;
`;

export const SubmitButtonWrap = styled.div`
  width: 100%;
  margin-top: 16px;
  padding: 16px;
`;
