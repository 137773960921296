import styled from 'styled-components';

export const LinkText = styled.a`
  color: ${({ theme }) => theme.colors.textLink};
`;

export const I = styled.span`
  font-style: italic;
`;

export const Separator = styled.div`
  height: 2px;
  width: 100%;
  background-color: ${({ theme }) =>
    theme.colors.backgroundSeparatorsSeparator};
`;
