import styled from 'styled-components';

export const Container = styled.div``;

export const InputsWrap = styled.div`
  display: flex;
  justify-content: center;
`;

export const Input = styled.input<{ isError: boolean }>`
  background-image: none;
  background-color: transparent;
  box-shadow: none;
  outline: none;

  width: 48px;
  height: 48px;
  margin-right: 12px;

  border-radius: 12px;

  border: 2px solid transparent;
  border-color: ${({ isError, theme }) =>
    isError ? theme.colors.inputError : theme.colors.inputStrokeDefault};

  &:focus {
    border-color: ${({ isError, theme }) =>
      isError ? theme.colors.inputError : theme.colors.inputStrokeFocus};
  }

  &:last-of-type {
    margin-right: 0;
  }

  ${({ theme }) => theme.fonts.title5Semibold};
  text-align: center;
  color: ${({ theme }) => theme.colors.inputTextPrimary};
  caret-color: transparent;
`;

export const ErrorText = styled.p`
  padding-top: 4px;
  ${({ theme }) => theme.fonts.subheadRegular};
  text-align: center;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.inputError};
`;
