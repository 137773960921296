import styled from 'styled-components';

export const CardWrapper = styled.section`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  width: 100%;

  padding: 24px 20px;
  border: 2px solid ${({ theme }) => theme.colors.buttonsMarshIntense};
  border-radius: 16px;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
`;

export const TextPlan = styled.p`
  margin: 0;
  ${({ theme }) => theme.fonts.bodyBold};
`;

export const TextFullPrice = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.blackSixth};
  ${({ theme }) => theme.fonts.paragraphRegular};
`;

export const TextOldPrice = styled.p`
  margin: 0;
  color: ${({ theme }) => theme.colors.blackSixth};
  ${({ theme }) => theme.fonts.paragraphRegular};
  text-decoration: line-through;
  text-decoration-color: ${({ theme }) => theme.colors.negative};
`;

export const TextDiscountedPrice = styled.p`
  margin: 0;
  ${({ theme }) => theme.fonts.bodySemibold}
`;
