import { useEffect, useRef, useState } from 'react';
import { Timeout } from 'types/types';

export const useTimer = (initialTimeInSeconds: number) => {
  const [msLeft, setMsLeft] = useState<number | null>(null);
  const timerRef = useRef<Timeout>();

  const stopTimer = () => {
    if (timerRef.current) {
      clearInterval(timerRef.current);
    }
  };

  const startTimer = () => {
    stopTimer();

    setMsLeft(initialTimeInSeconds);
    timerRef.current = setInterval(() => {
      setMsLeft(newTime => (newTime as number) - 1000);
    }, 1000);
  };

  useEffect(() => {
    startTimer();
  }, [initialTimeInSeconds]);

  useEffect(() => {
    if (msLeft === 0) {
      stopTimer();
    }
  }, [msLeft]);

  return { secondsLeft: msLeft ? msLeft / 1000 : 0 };
};
