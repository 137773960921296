import styled from 'styled-components';

interface IPaymentTypeCard {
  isActive?: boolean;
}

export const Container = styled.section`
  /* padding: 24px 20px; */

  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Title = styled.h2`
  ${({ theme }) => theme.fonts.title3Regular};

  margin-bottom: 8px;
`;

export const Subtitle = styled.p`
  ${({ theme }) => theme.fonts.title7Regular};

  color: ${({ theme }) => theme.colors.textAdditional};
`;

export const TopSubtitle = styled(Subtitle)`
  margin-bottom: 24px;
`;

export const PaymentTypeWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 8px;

  margin-bottom: 24px;

  width: 100%;
`;

export const PaymentTypeCard = styled.button<IPaymentTypeCard>`
  width: 164px;
  height: 56px;

  display: flex;
  justify-content: center;
  align-items: center;
  align-self: stretch;

  background: ${({ theme }) => theme.colors.backgroundSeparatorsPrimary};

  border: 2px solid
    ${({ theme, isActive }) =>
      isActive
        ? theme.colors.systemPrimary
        : theme.colors.backgroundSeparatorsSeparator};
  border-radius: 16px;
`;
