import { useRef } from 'react';
import { usePayproEvent } from './usePayproEvent';
// import { usePoolPaypro } from './usePoolPaypro';

export function usePayproPurchase({
  handlePaymentSuccess,
  handlePaymentWaiting,
}: {
  handlePaymentSuccess: () => void;
  handlePaymentWaiting: () => void;
}) {
  const navigatingRef = useRef<boolean>(false);

  usePayproEvent({ handlePaymentSuccess, handlePaymentWaiting, navigatingRef });
  // usePoolPaypro({ handlePaymentSuccess, handlePaymentWaiting, navigatingRef });
}
