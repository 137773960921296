import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;

  margin-bottom: 16px;

  display: flex;
  flex-direction: column;
`;

export const Row = styled.div<{ withMargin?: boolean }>`
  display: flex;
  justify-content: space-between;

  ${({ withMargin }) => withMargin && 'margin-bottom: 16px'};
`;

export const TopLabel = styled.p`
  ${({ theme }) => theme.fonts.paragraphMedium};
  color: ${({ theme }) => theme.colors.blackSixth};
`;

export const GreenTopLabel = styled(TopLabel)`
  color: ${({ theme }) => theme.colors.positive};
`;

export const TopPrice = styled.p`
  ${({ theme }) => theme.fonts.paragraphMedium};
  color: ${({ theme }) => theme.colors.blackSixth};
`;

export const DiscountPrice = styled.p`
  ${({ theme }) => theme.fonts.paragraphSemibold};
  color: ${({ theme }) => theme.colors.positive};
`;

export const BottomLabel = styled.p`
  ${({ theme }) => theme.fonts.paragraphBold};
`;

export const DiscountText = styled.p`
  align-self: flex-end;

  ${({ theme }) => theme.fonts.captionSemibold};
  color: ${({ theme }) => theme.colors.positive};
`;

export const Separator = styled.div`
  margin: 12px 0;
  height: 2px;
  background: rgba(0, 0, 0, 0.05);
`;
