import { FC, RefObject } from 'react';

import { getMultilineTextNode } from 'utils/formaters';

import { CommonInput } from 'components/Input/CommonInput';

import { AuthVariant } from './types';

import { Subtitle, Title } from './AuthModule.styles';

interface IEmailInputComponent {
  title: string;
  subtitle?: string;
  value: string;
  onChange: (value: string) => void;
  inputRef?: RefObject<HTMLInputElement>;
  placeholder: string;
  errorMessage?: string;
  isError?: boolean;
  variant: AuthVariant;
}

export const EmailInputComponent: FC<IEmailInputComponent> = ({
  title,
  subtitle,
  onChange,
  inputRef,
  variant,
  ...props
}) => {
  const handleChangeEmail = (text: string) => {
    const value = text.trim();
    onChange(value);
  };

  return (
    <>
      <Title variant={variant}>{getMultilineTextNode(title)}</Title>
      {subtitle && <Subtitle>{getMultilineTextNode(subtitle)}</Subtitle>}
      <CommonInput
        ref={inputRef}
        onChange={handleChangeEmail}
        type="email"
        {...props}
      />
    </>
  );
};
