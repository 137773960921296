interface INetworkRequestError {
  message: string;
  statusCode: number;
  params?: any;
}

export class NetworkRequestError {
  name = '';
  message = '';
  statusCode = 0;
  params: any;
  constructor({ message, statusCode, params }: INetworkRequestError) {
    this.message = message;
    this.statusCode = statusCode;
    this.params = params;
  }
}

export const handleDefaultInterception = (error: any) => {
  if (error.response.status === 408 || error.code === 'ECONNABORTED') {
    return Promise.reject(
      new NetworkRequestError({
        message: 'Timeout for request',
        statusCode: error.response.status,
      }),
    );
  }
  return Promise.reject(
    new NetworkRequestError({
      message: error.response.data?.message ?? 'Unknown error',
      statusCode: error.response.status,
      params: error.response.data,
    }),
  );
};
