import {
  ChangeEvent,
  FocusEvent,
  FocusEventHandler,
  forwardRef,
  InputHTMLAttributes,
  useState,
} from 'react';

import {
  ClearButton,
  Container,
  DescriptionText,
  ErrorText,
  InputWrap,
  Label,
  StyledTextInput,
} from './CommonInput.styles';

export interface InputProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'onChange'> {
  isError?: boolean;
  description?: string;
  label?: string;
  errorMessage?: string;
  isClear?: boolean;
  className?: string;
  onChange: (v: string, e?: ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
}

export const CommonInput = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      onChange,
      isError,
      description,
      label,
      errorMessage,
      onBlur,
      onClear,
      onFocus,
      isClear = true,
      className,
      ...props
    },
    ref,
  ) => {
    const [focused, setFocused] = useState(false);

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
      setFocused(true);
      onFocus?.(e);
    };

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
      setFocused(false);
      onBlur?.(e);
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange(e.target.value ?? '', e);
    };

    const handleClear = () => (onClear ? onClear() : onChange(''));

    return (
      <Container>
        {!!label && <Label>{label}</Label>}
        <InputWrap isError={isError} focused={focused}>
          <StyledTextInput
            ref={ref}
            className={className}
            onChange={handleChange}
            onFocus={handleFocus}
            onBlur={handleBlur}
            isError={isError}
            {...props}
          />
          <ClearButton
            isVisible={isClear && !!props.value}
            onClick={handleClear}>
            clear
          </ClearButton>
        </InputWrap>
        {isError && !!errorMessage && <ErrorText>{errorMessage}</ErrorText>}
        {!!description && <DescriptionText>{description}</DescriptionText>}
      </Container>
    );
  },
);
