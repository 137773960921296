import styled from 'styled-components';

interface IInputWrapper {
  isError?: boolean;
  isFocused?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

export const Label = styled.label`
  ${({ theme }) => theme.fonts.bodySemibold};
  color: ${({ theme }) => theme.colors.iconsSecondary};
`;

export const InputWrapper = styled.div<IInputWrapper>`
  display: flex;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  padding: 13px 20px 15px;
  border: 2px solid
    ${({ isFocused, isError, theme }) =>
      isError
        ? theme.colors.inputError
        : isFocused
        ? theme.colors.inputStrokeFocus
        : theme.colors.backgroundSeparatorsSeparator};
  border-radius: 8px;
`;

export const ErrorMessage = styled.p`
  padding: 4px 20px;
  ${({ theme }) => theme.fonts.subheadRegular};
  color: ${({ theme }) => theme.colors.inputError};
`;
