import styled from 'styled-components';

export const LogoWrapper = styled.div`
  flex: 1;
  padding: 16px;
`;

export const Content = styled.main`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 16px;

  width: 100%;
  max-width: 650px;
`;

export const CloseButtonWrap = styled.div`
  position: absolute;

  top: 20px;
  left: 20px;
`;
