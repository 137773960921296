import { useEffect } from 'react';

import { APP_ROUTES } from 'navigations/Routs';

import { useSubrouteName } from './useSubrouteName';

function combineSearchParams(
  params1: URLSearchParams,
  params2: URLSearchParams,
) {
  return new URLSearchParams({
    ...Object.fromEntries(params1),
    ...Object.fromEntries(params2),
  });
}

const KEY = 'prev_urlParams';

export const useRestoreQueryParams = () => {
  const { subrouteName } = useSubrouteName();

  function canReloadParams(pathname: string) {
    return pathname === APP_ROUTES.SUCCESS || pathname === APP_ROUTES.CONFIRM;
  }

  useEffect(() => {
    const url = new URL(window.location.href);
    const _prevParams = window.localStorage.getItem(KEY) ?? '';

    if (!_prevParams) {
      window.localStorage.setItem(KEY, url.search);
      return;
    }

    const prevParams = new URLSearchParams(_prevParams);

    if (url.searchParams.toString() === prevParams.toString()) {
      return;
    }

    if (canReloadParams(url.pathname)) {
      const combinedParams = combineSearchParams(url.searchParams, prevParams);

      window.localStorage.setItem(KEY, combinedParams.toString());

      window.location.search = combinedParams.toString();
    } else {
      window.localStorage.setItem(KEY, url.search);
    }
  }, [subrouteName]);
};
