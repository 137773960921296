import ReactGA from 'react-ga4';

import { AppConfig } from 'config';

type GA4Type = typeof ReactGA;

class GAClass {
  trackingId: string;
  clientId: string = '';
  lastPurchased = 0;

  constructor(id: string, trackingId: string) {
    ReactGA.initialize(id);
    this.trackingId = trackingId;
    this.getClientId().then(cid => {
      this.clientId = cid;
    });
  }

  private async _get<T>(method: string) {
    return new Promise<T>(resolve => {
      ReactGA.gtag('get', this.trackingId, method, (res: T) => {
        return resolve(res);
      });
    });
  }

  async getClientId() {
    return this._get<string>('client_id');
  }

  event: GA4Type['event'] = (...args) => {
    ReactGA.event(...args);
  };

  purchase(body: any) {
    const now = Date.now();
    const purchaseDelay = 15 * 1000; // 10 sec
    if (this.lastPurchased + purchaseDelay > now) {
      return;
    }

    this.lastPurchased = now;
    ReactGA.event('purchase', body);
  }
}

export const GA = new GAClass(AppConfig.GA_ID, 'UA-XXXXXXXX-Y');
