import { Link } from 'react-router-dom';

import { APP_ROUTES } from 'navigations/Routs';

import { Logo } from 'components/Icons/Logo';

export function LogoNavButton() {
  return (
    <Link to={APP_ROUTES.HOME}>
      <Logo />
    </Link>
  );
}
