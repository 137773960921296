export const colorsPalette = {
  //ACCENT
  blue: '#001EDC',
  iosBlue: '#147EFB',
  blueIntense: '#001AC2',
  lightPurple: '#8C96FF',
  purple: '#4B46E6',
  darkPurple: '#28239B',
  mint: '#BEE1D7',
  marshGreen: '#23786E',
  marshGreenIntense: '#0A5046',
  peachLight: '#F5DC82',
  peach: '#E69641',
  peachIntense: '#9B5514',
  fuxy: '#DC41E6',
  toxicSalad: '#D2FA5A',
  cream: '#FAF0E1',
  strichtOrange: '#E69641',
  accentBrand: '#0D7362',
  womanMain: '#D02C70',

  //MONOCHROME
  white: '#FFFFFF',
  alabaster: '#F7F7F7',
  snow: '#F2F2F2',
  ghost: '#E6E6E6',
  silver: '#AAAAAA',
  lightGray: '#777777',
  bulletGray: '#ECF3F2',
  tundora: '#444444',
  gray: '#333333',
  mineShaft: '#222222',
  graphitic: '#1F1F1F',
  charcoal: '#141414',
  nero: '#0D0D0D',
  black: '#000000',

  //OPACITY BLACK
  opacityBlack: (opacity: number) => `rgba(0, 0, 0, ${opacity})`,

  //OPACITY WHITE
  opacityWhite: (opacity: number) => `rgba(255, 255, 255, ${opacity})`,

  //SYSTEM
  negative: '#FF4747',
  positive: '#3DB83D',
  link: '#1F3CFF',

  //Uncategorized:
  romanSilver: '#828691',

  // STRICT BLACK
  blackFirst: '#000000',
  blackSecond: '#141414',
  blackThird: '#222222',
  blackFourth: 'rgba(34, 34, 34, 0.9)',
  blackFifth: 'rgba(0, 0, 0, 0.6)',
  blackSixth: 'rgba(0, 0, 0, 0.4)',
  blackSeventh: 'rgba(0, 0, 0, 0.2)',
  blackEigth: 'rgba(0, 0, 0, 0.1)',
  blackNinth: 'rgba(0, 0, 0, 0.05)',

  // STRICT WHITE
  whiteFirst: '#FFFFFF',
  whiteSecond: '#F2F2F2',
  whiteThird: '#E6E6E6',
  whiteFourth: 'rgba(230, 230, 230, 0.9)',
  whiteFifth: 'rgba(255, 255, 255, 0.6)',
  whiteSixth: 'rgba(255, 255, 255, 0.4)',
  whiteSeventh: 'rgba(255, 255, 255, 0.2)',
  whiteEighth: 'rgba(255, 255, 255, 0.1)',
  whiteNinth: 'rgba(255, 255, 255, 0.05)',
};
